const studentsPlacedData = [
    {
      sno: 1,
      name: "KANTAMSETTI  SREENIDHI",
      rollnumber: "17VV1A0112",
      branch: "Civil",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Cognizant Technology Solution",
    },
    {
      sno: 2,
      name: "KOSURI SAI DEVI SUSMITHA",
      rollnumber: "17VV1A0115",
      branch: "CIVIL",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 3,
      name: "MONDI TULASI VARA TEJASWINI",
      rollnumber: "17VV1A0118",
      branch: "CIVIL",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 4,
      name: "ANUSURI NAGENDRA SAI",
      rollnumber: "17VV1A0201",
      branch: "EEE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 5,
      name: "BATTULA LAKSHMI PRASANNA",
      rollnumber: "17VV1A0204",
      branch: "EEE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Tata Consultancy Services",
  },
  {
      sno: 6,
      name: "BINDU MADHAVI CHINTADA",
      rollnumber: "17VV1A0207",
      branch: "EEE",
      passingyear: "2020-2021",
      campus: "Off Campus",
      company: "Tata Consultancy Services",
  },
  {
      sno: 7,
      name: "D.DIVIJA",
      rollnumber: "17VV1A0216",
      branch: "EEE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Tata Consultancy Services",
  },
  {
      sno: 8,
      name: "MUKKAVALLI NIHARIKA",
      rollnumber: "17VV1A0235",
      branch: "EEE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Accenture",
  },
  {
      sno: 9,
      name: "Y HARSHA VARDHAN SRI SAI",
      rollnumber: "17VV1A0255",
      branch: "EEE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Cognizant Technology Solutions",
  },
  {
      sno: 10,
      name: "B BHARGAVA SRI SAI KRISHNA",
      rollnumber: "18VV5A0265",
      branch: "EEE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 11,
      name: "EADI MANVITHA",
      rollnumber: "17VV1A0310",
      branch: "MECH",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "FACE Prep",
  },
  {
      sno: 12,
      name: "MANDA VIJAYA",
      rollnumber: "17VV1A0330",
      branch: "MECH",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Cognizant Technology Solutions",
  },
  {
      sno: 13,
      name: "NEMALA PADMA",
      rollnumber: "17VV1A0339",
      branch: "MECH",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 14,
      name: "PADISETTI SANGEETH",
      rollnumber: "17VV1A0340",
      branch: "MECH",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 15,
      name: "SANAPALA SUPRAJA",
      rollnumber: "17VV1A0347",
      branch: "MECH",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "FACE Prep",
  },
  {
      sno: 16,
      name: "ABDUL RAQUEEB",
      rollnumber: "17VV1A0401",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 17,
      name: "ARETI SREYA",
      rollnumber: "17VV1A0403",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Tata Consultancy Services",
  },
  {
      sno: 18,
      name: "BODDU.RAJ KUMAR",
      rollnumber: "17VV1A0406",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 19,
      name: "BOLISETTI GURURAJA",
      rollnumber: "17VV1A0407",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Cognizant Technology Solutions",
  },
  {
      sno: 20,
      name: "SRI LAKSHMI BOMMISETTI",
      rollnumber: "17VV1A0408",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 21,
      name: "B.LAKSHMI PRIYA",
      rollnumber: "17VV1A0409",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Broadcom",
  },
  {
      sno: 22,
      name: "SRI RAM MAHESH CHUNDURU",
      rollnumber: "17VV1A0411",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Vayu Group",
  },
  {
      sno: 23,
      name: "DEVARASETTI VINAY KUMAR",
      rollnumber: "17VV1A0412",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Wipro",
  },
  {
      sno: 24,
      name: "GOLLAPALLI GAYATRI SIREESHA",
      rollnumber: "17VV1A0416",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Vayu Group",
  },
  {
      sno: 25,
      name: "GUNDEPALLI SAI SRIRAM",
      rollnumber: "17VV1A0418",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Tata Consultancy Services",
  },
  {
      sno: 26,
      name: "KAMBHAMPATI ANIRUDH",
      rollnumber: "17VV1A0420",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Infosys",
  },
  {
      sno: 27,
      name: "KATTA YESWANTH PRASANTH",
      rollnumber: "17VV1A0425",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Tata Consultancy Services",
  },
  {
      sno: 28,
      name: "KINTALI SAICHARAN",
      rollnumber: "17VV1A0426",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Cognizant Technology Solutions",
  },
  {
      sno: 29,
      name: "KORUPOLU VANAJA",
      rollnumber: "17VV1A0428",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Vayu Group",
  },
  {
      sno: 30,
      name: "MANUKONADA VAMSI KRISHNA REDDY",
      rollnumber: "17VV1A0429",
      branch: "ECE",
      passingyear: "2020-2021",
      campus: "On Campus (Virtual)",
      company: "Tata Consultancy Services",
  },
  {
    sno: 31,
    name: 'PRANEETH KOUSHIK',
    rollnumber: '17VV1A0434',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
},
{
    sno: 32,
    name: 'NALLANA KEERTHANA',
    rollnumber: '17VV1A0435',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 33,
    name: 'NERELLA MOHITH',
    rollnumber: '17VV1A0436',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
},
{
    sno: 34,
    name: 'PONNAGANTI VASUDEVI',
    rollnumber: '17VV1A0440',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
},
{
    sno: 35,
    name: 'SHEIK SALMA',
    rollnumber: '17VV1A0442',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 36,
    name: 'MAMATHA REDDY TAMILSETTI',
    rollnumber: '17VV1A0445',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Accenture',
},
{
    sno: 37,
    name: 'ULLAKULA ANEESH',
    rollnumber: '17VV1A0447',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Broadcom',
},
{
    sno: 38,
    name: 'VALAVALA DURGA PRASAD',
    rollnumber: '17VV1A0448',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Medha Servo Pvt ltd.',
},
{
    sno: 39,
    name: 'VALLAMSETTI ARAVIND NAG',
    rollnumber: '17VV1A0449',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Wipro',
},
{
    sno: 40,
    name: 'VERICHERLA RAJU',
    rollnumber: '17VV1A0451',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'Triecoders Pvt.Ltd',
},
{
    sno: 41,
    name: 'VYKUNTAM BHAVANA',
    rollnumber: '17VV1A0452',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
},
{
    sno: 42,
    name: 'YARLAGADDA NARESH',
    rollnumber: '17VV1A0453',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'Triecoders Pvt.Ltd',
},
{
    sno: 43,
    name: 'PADALA ABHIRAM',
    rollnumber: '18VV5A0463',
    branch: 'ECE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Medha Servo Pvt ltd.',
},
{
    sno: 44,
    name: 'A.ABHILASH SAI',
    rollnumber: '17VV1A0501',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 45,
    name: 'BATTA SAI PRANEETHA',
    rollnumber: '17VV1A0502',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 46,
    name: 'B JYOTHI SURYA SRAVANTHI',
    rollnumber: '17VV1A0503',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
},
{
    sno: 47,
    name: 'BHEESETTY JYOTHI SURYA SRAVANTHI',
    rollnumber: '17VV1A0503',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 48,
    name: 'B.VEDASREE',
    rollnumber: '17VV1A0504',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 49,
    name: 'BOGADI CHANDRIKA PRIYA',
    rollnumber: '17VV1A0505',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'Triecoders Pvt.Ltd',
},
{
    sno: 50,
    name: 'BALA MALLIKHARJUNA SAI TEJA BORRA',
    rollnumber: '17VV1A0506',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 51,
    name: 'BORRA HARISH',
    rollnumber: '17VV1A0507',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'Sails Software Solutions',
},
{
    sno: 52,
    name: 'BUSAM CH B N K V GANESH',
    rollnumber: '17VV1A0509',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
},
{
    sno: 53,
    name: 'CHINTAPALLI MANIKUMAR',
    rollnumber: '17VV1A0510',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 54,
    name: 'CHIRUVELLA VINAY BABU',
    rollnumber: '17VV1A0511',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 55,
    name: 'DUVVURI VENKATA SOWJANYA SRI HARINI',
    rollnumber: '17VV1A0512',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Amazon',
},
{
    sno: 56,
    name: 'DUVVURI VENKATA SOWMYA SRI HARINI',
    rollnumber: '17VV1A0512',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 57,
    name: 'G RAJ KUMAR',
    rollnumber: '17VV1A0513',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Vayu Group',
},
{
    sno: 58,
    name: 'GANTA RAJ SANKEERTH',
    rollnumber: '17VV1A0517',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 59,
    name: 'ISWAHANI SAJID ALI',
    rollnumber: '17VV1A0517',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'Triecoders Pvt.Ltd',
},
{
    sno: 60,
    name: 'SARANYA JAMI',
    rollnumber: '17VV1A0518',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Accenture',
},
{
    sno: 61,
    name: 'KOLLABATHULA ROSHINI',
    rollnumber: '17VV1A0521',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
},
{
    sno: 62,
    name: 'LUKALAPU RAGHURAM',
    rollnumber: '17VV1A0522',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
},
{
    sno: 63,
    name: 'MAHANTHI AKHILA',
    rollnumber: '17VV1A0523',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 64,
    name: 'MAJJI JAHNAVI',
    rollnumber: '17VV1A0524',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 65,
    name: 'MAREPALLI SAI ANIRUDH',
    rollnumber: '17VV1A0525',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
},
{
    sno: 66,
    name: 'MEDISETTI HEMALATHA',
    rollnumber: '17VV1A0526',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
},
{
    sno: 67,
    name: 'MANIKRISHNA NAGULAPALLI',
    rollnumber: '17VV1A0528',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
},
{
  sno: 68,
  name: 'NANDIPATI DURGA PRASAD REDDY',
  rollnumber: '17VV1A0529',
  branch: 'CSE',
  passingyear: '2020-2021',
  campus: 'On Campus (Virtual)',
  company: 'Cognizant Technology Solutions',
  },
  {
  sno: 69,
  name: 'NARAVA KAVYA',
  rollnumber: '17VV1A0530',
  branch: 'CSE',
  passingyear: '2020-2021',
  campus: 'On Campus (Virtual)',
  company: 'Paytm Techops',
  },
  {
  sno: 70,
  name: 'NASAKA AJAY',
  rollnumber: '17VV1A0531',
  branch: 'CSE',
  passingyear: '2020-2021',
  campus: 'On Campus (Virtual)',
  company: 'Hexaware Technologies',
  },
  {
    sno: 71,
    name: 'PALAKOLLU SAHITHI JYOTHSNA',
    rollnumber: '17VV1A0532',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Amazon',
    },
    {
    sno: 72,
    name: 'PEETHANI PAVAN SAI KUMAR',
    rollnumber: '17VV1A0533',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
    },
    {
    sno: 73,
    name: 'PONDALA PRANAYADURGA',
    rollnumber: '17VV1A0534',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 74,
    name: 'RAJAMAHENDRAVARAPU ADITYA',
    rollnumber: '17VV1A0536',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Wipro',
    },
    {
    sno: 75,
    name: 'REDDY LAKSHMI TEJASWI',
    rollnumber: '17VV1A0537',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
    },
    {
    sno: 76,
    name: 'SANDEEP ANUMULA',
    rollnumber: '17VV1A0538',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
    },
    {
    sno: 77,
    name: 'SASANALA POOJA ALEKHYA',
    rollnumber: '17VV1A0539',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Hexaware Technologies',
    },
    {
    sno: 78,
    name: 'SHAIK RIZWANA KHATUN',
    rollnumber: '17VV1A0540',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Hexaware Technologies',
    },
    {
    sno: 79,
    name: 'SHEIK SAYYED BASHEER',
    rollnumber: '17VV1A0541',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
    },
    {
    sno: 80,
    name: 'SK HUSSAIN',
    rollnumber: '17VV1A0542',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'APFCMSS',
    },
    {
    sno: 81,
    name: 'SRI HARSHA DUMALA',
    rollnumber: '17VV1A0543',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 82,
    name: 'SANDEEP ROY SUNNAPU',
    rollnumber: '17VV1A0544',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'GreyCampus',
    },
    {
    sno: 83,
    name: 'THADI SHYAM KUMAR',
    rollnumber: '17VV1A0546',
    branch: 'CSE',
    passingyear: '2020-2023',
    campus: 'On Campus',
    company: 'Sails Software Solutions',
    },
    {
    sno: 84,
    name: 'THADIPARTHI SOWMYA',
    rollnumber: '17VV1A0547',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 85,
    name: 'DEEPTHI MANOJA',
    rollnumber: '17VV1A0553',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'Triecoders Pvt.Ltd',
    },
    {
    sno: 86,
    name: 'DEEPTHI MANOJA',
    rollnumber: '17VV1A0553',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Accenture',
    },
    {
    sno: 87,
    name: 'VAHINI KUMAR GUTTULA',
    rollnumber: '18VV5A0561',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
    },
    {
    sno: 88,
    name: 'KOLLI NEELIMA',
    rollnumber: '18VV5A0562',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 89,
    name: 'DADI SIRISHA',
    rollnumber: '18VV5A0563',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
    },
    {
    sno: 90,
    name: 'IMMANDI JOSEPH KISHORE',
    rollnumber: '18VV5A0564',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Technology Solutions',
    },
    {
    sno: 91,
    name: 'KESANA SRI DEEPTHI',
    rollnumber: '18VV5A0565',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Vayu Group',
    },
    {
    sno: 92,
    name: 'GANTA RAJ SANKEERTH',
    rollnumber: '16VV1A0517',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'Sails Software Solutions',
    },
    {
    sno: 93,
    name: 'PONNAMALLA JAYA CHANDRA',
    rollnumber: '16VV1A0541',
    branch: 'CSE',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 94,
    name: 'A DIVYA GNANA BHRAMARAMBIKA',
    rollnumber: '17VV1A1201',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 95,
    name: 'BARATAM GNANA PRASANNA',
    rollnumber: '17VV1A1202',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 96,
    name: 'BARATAM LAKSHMI PRASANNA',
    rollnumber: '17VV1A1203',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 97,
    name: 'CHIKKAM V S AKSHARA SPOORTHY',
    rollnumber: '17VV1A1205',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Abda Digital',
    },
    {
    sno: 98,
    name: 'DIVI UTKALA',
    rollnumber: '17VV1A1207',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
    },
    {
    sno: 99,
    name: 'DOKULA SAI LIKHITHA',
    rollnumber: '17VV1A1208',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Hexaware Technologies',
    },
    {
    sno: 100,
    name: 'GAJULA YASWANTH',
    rollnumber: '17VV1A1209',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 101,
    name: 'GANTA PRAKHYA',
    rollnumber: '17VV1A1211',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 102,
    name: 'GINJALA ANUSHA',
    rollnumber: '17VV1A1212',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Accenture',
    },
    {
    sno: 103,
    name: 'GOMANGI NAVEEN',
    rollnumber: '17VV1A1213',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'APFCMSS',
    },
    {
    sno: 104,
    name: 'INDUGU VENKAT SAI PRUDHVI RAJ',
    rollnumber: '17VV1A1215',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Broadcom',
    },
    {
    sno: 105,
    name: 'JANUPALLI PRANAY',
    rollnumber: '17VV1A1216',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus',
    company: 'GreyCampus',
    },
    {
    sno: 106,
    name: 'KAMBHAPATI VIKASH',
    rollnumber: '17VV1A1218',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
    sno: 107,
    name: 'K.VIKASH',
    rollnumber: '17VV1A1218',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Wipro',
    },
    {
    sno: 108,
    name: 'K.VENKATA SAI KIRAN',
    rollnumber: '17VV1A1219',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
    },
    {
    sno: 109,
    name: 'PRAHARSHITHA METLA',
    rollnumber: '17VV1A1222',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Tata Consultancy Services',
    },
    {
    sno: 110,
    name: 'NUKALA TEJASWI',
    rollnumber: '17VV1A1225',
    branch: 'IT',
    passingyear: '2020-2021',
    campus: 'On Campus (Virtual)',
    company: 'Infosys',
    },
    {
      sno: 111,
      name: 'PALLELA VENKATA MANI KRISHNA',
      rollnumber: '17VV1A1226',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Tata Consultancy Services',
      },
      {
      sno: 112,
      name: 'PEDAPUDI KUSUMA',
      rollnumber: '17VV1A1227',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Accenture',
      },
      {
      sno: 113,
      name: 'PERVELA SAI DIVYA',
      rollnumber: '17VV1A1228',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus',
      company: 'Sails Software Solutions',
      },
      {
      sno: 114,
      name: 'YESHWANTH RAYAVARAPU',
      rollnumber: '17VV1A1233',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Vayu Group',
      },
      {
      sno: 115,
      name: 'SAGI SUMA',
      rollnumber: '17VV1A1234',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Abda Digital',
      },
      {
      sno: 116,
      name: 'SAGI SUMA',
      rollnumber: '17VV1A1234',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Cognizant Technology Solutions',
      },
      {
      sno: 117,
      name: 'SAI TEJA GARBHANI',
      rollnumber: '17VV1A1235',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 118,
      name: 'SEELAMANTHULA VASU SAI SOURABH',
      rollnumber: '17VV1A1236',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Tata Consultancy Services',
      },
      {
      sno: 119,
      name: 'SHAIK HASHMATH',
      rollnumber: '17VV1A1237',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Tata Consultancy Services',
      },
      {
      sno: 120,
      name: 'TAMMA LOHITH REDDY',
      rollnumber: '17VV1A1239',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 121,
      name: 'TANTATI KARUNYA',
      rollnumber: '17VV1A1240',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus',
      company: 'Sails Software Solutions',
      },
      {
      sno: 122,
      name: 'TURAKALA GEETA SRI',
      rollnumber: '17VV1A1241',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 123,
      name: 'V DEEPIKA RANI',
      rollnumber: '17VV1A1242',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 124,
      name: 'V.SRIKALA',
      rollnumber: '17VV1A1243',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Tata Consultancy Services',
      },
      {
      sno: 125,
      name: 'VANDANA PRANAVANANDA',
      rollnumber: '17VV1A1244',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Abda Digital',
      },
      {
      sno: 126,
      name: 'VILLURI MANIKYA VENKATA GNANESWAR',
      rollnumber: '17VV1A1246',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Hexaware Technologies',
      },
      {
      sno: 127,
      name: 'YERRA RATNASRI',
      rollnumber: '17VV1A1248',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 128,
      name: 'BODDEPALLI SOWJANYA',
      rollnumber: '18VV5A1262',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Accenture',
      },
      {
      sno: 129,
      name: 'SAMAYAM PRAVALLIKA',
      rollnumber: '18VV5A1264',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 130,
      name: 'HARI PRIYA NIMMALURI',
      rollnumber: '18VV5A1271',
      branch: 'IT',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Accenture',
      },
      {
      sno: 131,
      name: 'KAGITHA ASHOK KUMAR',
      rollnumber: '17VV1A3108',
      branch: 'MET',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 132,
      name: 'MAMIDISETTI PRAMODH RATNA GOPAL',
      rollnumber: '17VV1A3111',
      branch: 'MET',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Srikalahasti Pipes Ltd.Tirupathi',
      },
      {
      sno: 133,
      name: 'V.K.MALLIKA DARSHAN',
      rollnumber: '17VV5A3131',
      branch: 'MET',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Srikalahasti Pipes Ltd.Tirupathi',
      },
      {
      sno: 134,
      name: 'SHAIK SUBHANI',
      rollnumber: '18VV5A3131',
      branch: 'MET',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Srikalahasti Pipes Ltd.Tirupathi',
      },
      {
      sno: 135,
      name: 'URIVITI HARI PRASAD',
      rollnumber: '18VV5A3133',
      branch: 'MET',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Srikalahasti Pipes Ltd.Tirupathi',
      },
      {
      sno: 136,
      name: 'GARA VIKAS',
      rollnumber: '18VV5A3134',
      branch: 'MET',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Srikalahasti Pipes Ltd.Tirupathi',
      },
      {
      sno: 137,
      name: 'POTLADA VENKATESH',
      rollnumber: '18VV5A3135',
      branch: 'MET',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Srikalahasti Pipes Ltd.Tirupathi',
      },
      {
      sno: 138,
      name: 'Kalyani',
      rollnumber: '19VV5F0042',
      branch: 'MCA',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Prodigy Systems',
      },
      {
      sno: 139,
      name: 'Maturu Priyanka',
      rollnumber: '19VV5F0046',
      branch: 'MCA',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Prodigy Systems',
      },
      {
      sno: 140,
      name: 'Talatam Durga Srilakshmi',
      rollnumber: '19VV5F0048',
      branch: 'MCA',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Prodigy Systems',
      },
      {
      sno: 141,
      name: 'VANAPALLI HEMALATHA',
      rollnumber: '17VV1F0026',
      branch: 'MCA',
      passingyear: '2020-2021',
      campus: 'On Campus (Virtual)',
      company: 'Prodigy Systems',
      }

  ];
  
  export default studentsPlacedData;
  