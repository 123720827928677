// Sample data for notifications
export const getNotifications = () => [
    {
        description: `DAA/JNTU-GV-Request to clear outstanding dues of a Affiliation/University common Serives Fee up to Academic Year 2022-2023 to the University`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1V_bfflY-KLQc8gfh5TFuhmwLw5_5HnsM/view?usp=sharing",
        date: "23",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
     {
        description: `Revised Academic calanders for  B. Pharmacy III year II Semester AY 2023-24`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1IU67Uo-41BT6sXZx4iAwL_Ex5bQwEQ3s/view?usp=drivesdk",
        date: "20",
        month: "Dec",
        year: "2023",
        isLink: true,
      }, {
        description: `Revised Academic calanders for B. Tech III year II Semester AY 2023-24`,
        type: "notifications",
        link: "https://drive.google.com/file/d/11OXj7YlOOWJC61BwbhOVX2YkvqY4L_5v/view?usp=drivesdk",
        date: "20",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
    {
        description: `I B. Tech I Semester Regular and Supplementary Notification`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1klTCR02ufDCCQ3xMRTrEDz5Qik7qWcdP/view?usp=sharing",
        date: "15",
        month: "Dec",
        year: "2023",
        isLink: true,
      },{
        description: `Revaluation of I B. Tech I Semester Supplementary Examinations July -2023 `,
        type: "notifications",
        link: "https://drive.google.com/file/d/19XoZcL50EhcyfsF-XW0zLGmTT-B9okty/view?usp=sharing",
        date: "15",
        month: "Dec",
        year: "2023",
        isLink: true,
      },{
        description: `Application form for Revaluation`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1ORgylAzE_1NJ9lkjOGlhEDghv2vSP6yu/view?usp=sharing",
        date: "15",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: `I B. Tech I Semester Regular and Supplementary Notification`,
        type: "examination",
        link: "https://drive.google.com/file/d/1klTCR02ufDCCQ3xMRTrEDz5Qik7qWcdP/view?usp=sharing",
        date: "15",
        month: "Dec",
        year: "2023",
        isLink: true,
      },{
        description: `Revaluation of I B. Tech I Semester Supplementary Examinations July -2023 `,
        type: "examination",
        link: "https://drive.google.com/file/d/19XoZcL50EhcyfsF-XW0zLGmTT-B9okty/view?usp=sharing",
        date: "15",
        month: "Dec",
        year: "2023",
        isLink: true,
      },{
        description: `Application form for Revaluation`,
        type: "examination",
        link: "https://drive.google.com/file/d/1ORgylAzE_1NJ9lkjOGlhEDghv2vSP6yu/view?usp=sharing",
        date: "15",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: `Application-form-for-Pre-Ph.D-Examination`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1tAzKvlxWNmHMvkynN44Jyck-btPAzRSa/view?usp=sharing",
        date: "14",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: `Pre-Ph.D. Examination-2023-Notification`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1cJrdKJ7GP0HumNrP7M1Iq2YFVRXWu4S5/view?usp=sharing",
        displaylink: " https://docs.google.com/forms/d/10BCpsGBxsfzIkqQgXdR21DIdu37DedMqMsAiHMdyoCY/edit?pli=1",
        displaytext: "Click here to Register",
        date: "14",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: `VIKSIT BHARAT @2047`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1rWztYnZOlyvx60MRbZKAVXtm-RnLseiC/view?usp=sharing",
        date: "11",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: `A 5-Day International Short Term Training Program(STTP) on "Cyber Security: Browser Security`,
        type: "workshop",
        // link: "https://drive.google.com/file/d/1KXJ8G2g81tzpjaz5zbFeHOgiqfr852TI/view?usp=sharing",
        displaylink: "https://forms.gle/W3PwBzhPJvqB7QiS7",
        displaytext: "Click here to register",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: `JNTUGV-DAA- "Certificate Verification " - Admissions- for UG (B.Tech Courses) for the Academic Year 2023-2024`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1t5qIU8dMnK1XMoZrYBG6X1Y6WLmDj05d/view?usp=sharing",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      }, {
        description: "JNTUGV_3-1-R20 Supple TIME TABLE December 2023",
        type: "notifications",
        link: "https://drive.google.com/file/d/16EPIsRX3jeRbI2e-MZMaV9O6pG_-aOF9/view?usp=sharing",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      }, {
        description: "JNTUGV_3-1-R19 Supple TIME TABLE December 2023",
        type: "notifications",
        link: "https://drive.google.com/file/d/1Tp8iVEUmHyTjRvKleICh8M9TBC5Yjnox/view?usp=sharing",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: "JNTUGV_3-1-R16-Supple- TIME TABLE December 2023",
        type: "notifications",
        link: "https://drive.google.com/file/d/1_B3cciESIHVULCYeKBNz-NqxKBsPIQrO/view?usp=sharing",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      }, {
        description: "JNTUGV_3-1-R20 Supple TIME TABLE December 2023",
        type: "examination",
        link: "https://drive.google.com/file/d/16EPIsRX3jeRbI2e-MZMaV9O6pG_-aOF9/view?usp=sharing",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      }, {
        description: "JNTUGV_3-1-R19 Supple TIME TABLE December 2023",
        type: "examination",
        link: "https://drive.google.com/file/d/1Tp8iVEUmHyTjRvKleICh8M9TBC5Yjnox/view?usp=sharing",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: "JNTUGV_3-1-R16-Supple- TIME TABLE December 2023",
        type: "examination",
        link: "https://drive.google.com/file/d/1_B3cciESIHVULCYeKBNz-NqxKBsPIQrO/view?usp=sharing",
        date: "07",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
    {
        description: "JNTUGV-DAA-UGC 2(f), 12(B) and Autonomous Status-Change of Affiliation from JNTUK, Kakinada to JNTUGV, Vizianagaram",
        type: "notifications",
        link: "https://drive.gooexaminationle.com/file/d/1MoCjsG1Fb_bLTgt-tZxuEzFDOMRHm0gQ/view?usp=sharing",
        date: "06",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: "Circular -Holiday-Cyclone Michuang- 5-12-2023",
        type: "notifications",
        link: "https://drive.google.com/file/d/19nwWCmz3G2yvGXKB5klf7ZmVcAr_iN_o/view?usp=sharing",
        date: "04",
        month: "Dec",
        year: "2023",
        isLink: true,
      },
      {
        description: "IV B.Tech I Semester Jumbling centers",
        type: "examination",
        link: "https://drive.google.com/file/d/11xMjbXC8QFDedoJ8cKLP6YS2fh0P323I/view?usp=sharing",
        date: "30",
        month: "Nov",
        year: "2023",
        isLink: true,
      }, {
        description: "IV-I B.Tech R16 Supple-Time Table-Dec-2023 ",
        type: "examination",
        link: "https://drive.google.com/file/d/1vIXgi5138lBTyPm1rIR-kX36Tbll8u9x/view?usp=sharing",
        date: "30",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "IV-I B.Tech R19 Supple-Time Table-Dec-2023 ",
        type: "examination",
        link: "https://drive.google.com/file/d/1QWnLi30dkTFsGk2xRtm5Un_G1Fy7wI-d/view?usp=sharing",
        date: "30",
        month: "Nov",
        year: "2023",
        isLink: true,
      }, {
        description: "Revised IV-I B.Tech R20 Regular-Time Table-Dec-2023",
        type: "examination",
        link: "https://drive.google.com/file/d/19fQCUNwdVYZ96kRgH7q12H3uz2nNoZXz/view?usp=sharing",
        date: "30",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "Volley Ball (Women) Team Selected Players List",
        type: "sports",
        link: "https://drive.google.com/file/d/1vDeOmbk83fv-6jHVOrkn3tXL4a6FN5Zt/view?usp=sharing",
        date: "29",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "Weightlifting (Men & Women) Team Selected Players List",
        type: "sports",
        link: "https://drive.google.com/file/d/1X8FfvLe3QNG9VRrTAgElp3kZZ5aZoxX8/view?usp=sharing",
        date: "29",
        month: "Nov",
        year: "2023",
        isLink: true,
      }, {
        description: "Academic Calendar for I Year Pharma D for the Academic Year 2023-24",
        type: "notifications",
        link: "https://drive.google.com/file/d/1WP--NiUYNNfPR1nJrqGR5bOEQsP84s7b/view?usp=sharing",
        date: "28",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "Academic Calendar for I Year B Pharmacy for the Academic Year 2023-24 ",
        type: "notifications",
        link: "https://drive.google.com/file/d/19Zkp0k64ouvlYw2awLs7IY408OqsZcbA/view?usp=sharing",
        date: "28",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: `Yoga (M & W) Team Selection Trails`,
        type: "sports",
        link: "https://drive.google.com/file/d/10-uVSgm0HRa8xxPliD4FM7FdelctQQuE/view?usp=sharing",
        date: "22",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: `III B.Tech ISem RegularSupply R20, R19, R16 Notification`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1c5cvQ1T45hcmFNE7lntQ5DFZPt8bpdRY/view?usp=sharing",
        date: "21",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: `Up-Skilling Program In "IoT Technologies" In Collaboration With C-DAC and IEEE and CSC Academy /CSC e-Governance Services India Limited.
        (check workshops tab for more details)`,
        type: "notifications",
        link: "https://drive.google.com/file/d/15GSrtZ6Sq8-mbAad6u0wlr6xu98PdILV/view?usp=sharing",
        date: "15",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "JNTUGV Inter Collegiate Tournaments Dates & Venues 2023-24",
        type: "sports",
        link: "https://drive.google.com/file/d/1XvQrUyp63GuYrSgFP_Bd4a7u28EEVAyl/view?usp=sharing",
        date: "09",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "DR&D-Circular-Research Methodology",
        type: "notifications",
        link: "https://drive.google.com/file/d/1esNxbq-QNf_DouXxG9wz0pGV6RZsBMCq/view?usp=sharing",
        date: "07",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "Circular (For New NSS Units)",
        type: "notifications",
        link: "https://drive.google.com/file/d/1mt-xd1NLKdCJtqpx7jV0gaF-G_VFgk46/view?usp=sharing",
        date: "06",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "RRM Notification for AY 2019-20 & Ealier Batches",
        type: "notifications",
        link: "https://drive.google.com/file/d/1SXtfk8ZVcFUZr9wHGHquhwNBiHtuBsZg/view?usp=sharing",
        date: "04",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "RRM-Application Format",
        type: "notifications",
        link: "https://drive.google.com/file/d/1MkePk2qr6fExE33Wp1L8SwMH5q9NtEaq/view?usp=sharing",
        date: "04",
        month: "Nov",
        year: "2023",
        isLink: true,
      },
      {
        description: "Limited Recruitment notification for SC/ST Backlog Vacancies",
        type: "notifications",
        link: "https://drive.google.com/file/d/1vD1BH9VNxFZ3660Tq9TYUWAp8fHMhrXE/view?usp=sharing",
        date: "30",
        month: "Oct",
        year: "2023",
        isLink: true,
      },
      {
        description: `Recruitment notification for Assistant Professors and Academic Non-Vacation Positions
                      (BC Backlog and Regular Vacancies)`,
        type: "notifications",
        link: "https://drive.google.com/file/d/1ZVrwi5iApIEZr5-2eg9MAeXgijEBWcKp/view?usp=sharing",
        date: "30",
        month: "Oct",
        year: "2023",
        isLink: true,
      },
      {
        description: "JNTUGV-Detailed Notification for Associate Professors",
        type: "notifications",
        link: "https://drive.google.com/file/d/1BqL8z-1_G_bR-9qjz5SQrF3ItE-nnMdw/view?usp=sharing",
        date: "30",
        month: "Oct",
        year: "2023",
        isLink: true,
      },
      {
        description: "JNTUGV-Detailed Notification for Professors",
        type: "notifications",
        link: "https://drive.google.com/file/d/1LkPzCEEBGsK6xVvva3uXtDGEv9hEmHu1/view?usp=sharing",
        date: "30",
        month: "Oct",
        year: "2023",
        isLink: true,
      },
      {
        description:
          "IV B.Tech - I Sem Reg & Supple Exams Notification-December-2023",
        link: "https://drive.google.com/file/d/18rWsMfC8RGnEugCcDhP3h5wnpj4sxS4o/view?usp=share_link",
        date: "13",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "IV B.Pharmacy - I Sem Reg & Supple Exams Notification-December-2023",
        link: "https://drive.google.com/file/d/1dqy_rgi86_u9CMydWSrV50LO65Ddy4Xz/view?usp=share_link",
        date: "13",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "List of Examiners for Spot Valuation of MBA- IV Semester Regular & Supply-July-2023",
        link: "https://drive.google.com/file/d/1kEWYzYKvIU7mupDJ37AJARETVZ1aD3vN/view?usp=share_link",
        date: "13",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calander for III Pharm D Academic Year 2023-24",
        link: "https://drive.google.com/file/d/1KTU0LGyTzSQN6SwKRTiUoCC8HYkxP56H/view?usp=share_link",
        date: "11",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calander for II Pharm D Academic Year 2023-24",
        link: "https://drive.google.com/file/d/1C98jPJyDk3AY9ZgcZ0l2YCfmxzBIq9y5/view?usp=share_link",
        date: "11",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "MBA -III Sem Reg & Supply Exams Notification,Dec 2023",
        link: "https://drive.google.com/file/d/1kEWYzYKvIU7mupDJ37AJARETVZ1aD3vN/view?usp=share_link",
        date: "11",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "MTECH -III Sem Reg & Supply Exams Notification,Dec 2023",
        link: "https://drive.google.com/file/d/1yp_Kqj8nPwu2hdmUg38SQ-BWkqWwf1u3/view?usp=share_link",
        date: "11",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "MCA -III Sem R&S Notification , Dec 2023",
        link: "https://drive.google.com/file/d/1VSTiSf7kSLO0bszctgAEmxKHN_znYMfS/view?usp=share_link",
        date: "11",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calendar for IV Year B. Tech Academic Year 2023-24",
        link: "https://drive.google.com/file/d/1eekBCszWGkxrJFDxNgDpabta75U2UpUy/view?usp=share_link",
        date: "07",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calendar for I Year MBA and MCA",
        link: "https://drive.google.com/file/d/12h7w_EuGdnR3gqAEyM68HcLmUIx26qaI/view?usp=share_link",
        date: "04",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calendar for I Year M. Tech and M. Pharmacy",
        link: "https://drive.google.com/file/d/18SIawj2DntYndNAsjXLA7-3s1f-23G9q/view?usp=share_link",
        date: "04",
        month: "Oct",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "APSCHE-BCDE-UNICEF-P2E Program",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1695812280-34056APSCHE UNICEF Launched P2E Program.pdf",
        date: "27",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calender for II year B. Pharmacy Academic Year 2023-24",
        link: "https://drive.google.com/file/d/1IrawmiHcz5rP2le0Q5pXrNyt75psQnzH/view?usp=sharing",
        date: "27",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Ph.D-Tution Fee-Application.",
        link: "https://drive.google.com/file/d/1rCCC2qpgSXno7O-8qpwcr6SUfFHxGDtz/view?usp=sharing",
        date: "26",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Ph.D-Tution Fee-Circular.",
        link: "https://drive.google.com/file/d/1tVcX10y17yM0nQHx6a06zkrtQtItclWZ/view?usp=sharing",
        date: "26",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "IEEE Vizag Bay COMSOC/SPS Distinguished Lecture by Dr.Sudhir Dixit, San\u00a0Francisco,\u00a0USA.",
        link: "https://drive.google.com/file/d/1AGieJ4z5hizI-rPQXyuvgy2pucOFHpRt/view?usp=sharing",
        date: "23",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Most Urgent: Appointment of Student Grievances Redressal Committee (SGRC) and Ombud Persons in Constituent and Affiliated Colleges",
        link: "https://drive.google.com/file/d/164BCaHh39X0JwmkRoQ5HqVUMfqZFGaHV/view?usp=sharing",
        date: "22",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "MBA I semester examinations Feb-2023 Revaluation/ Recounting -Submission of Application forms-Reg",
        link: "https://drive.google.com/file/d/1-KBGH1IDFadsCWD65ggn4AeyMZh0B3vH/view?usp=sharing",
        date: "22",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "IV B.Tech-I Sem (R20) I-MID Examination Time Table-September-2023",
        link: "https://drive.google.com/file/d/1XrB-mtU__G9lTfjzBLX5pVVZnesRw__h/view?usp=sharing",
        date: "22",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "MCA-III Sem (R20) I MID Examination Time Table-September-2023",
        link: "https://drive.google.com/file/d/1y0iXYQbA6JAkg17zFIPFfLx3tCYgk4zY/view?usp=sharing",
        date: "20",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "MBA-III Sem (R19) I  MID Examination Time Table-September-2023",
        link: "https://drive.google.com/file/d/1ZxgOLoyb7qLhYwXczgPO-y9ijPy28ZX_/view?usp=sharing",
        date: "20",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "M.Tech-III Sem (R19) I-MID Examination Time Table-September-2023",
        link: "https://drive.google.com/file/d/1XFeInyqz7zwn4IVaI2-O6q44JzhYuq9H/view?usp=sharing",
        date: "20",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Notification For I B.Pharmacy II Semester Regular/Supply Exams SEP/OCT-2023",
        link: "https://drive.google.com/file/d/1DBidkF3fnpEn3szSTe97NfLur1xF-FFv/view?usp=sharing",
        date: "19",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Poetry competitions on the eve of Gurajada apparoa 161th birthday celebrations\u00a0at\u00a0JNTUGV",
        link: "https://drive.google.com/file/d/1jjNNwbVNWWcDxQNWFDHFwCyJF0qgHcbl/view?usp=sharing",
        date: "19",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Student Grievance Redressal Form",
        link: "https://drive.google.com/file/d/1Ao_AX3kc0gzjmyww--puYDnDNtRaaIUY/view?usp=sharing",
        date: "15",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "JNTUGV-UG-B.Tech.- R23 Regulations",
        link: "https://drive.google.com/file/d/1Yh4HBl1QsiuVkfK1Osvsd9vcS5u9sBgO/view?usp=sharing",
        date: "12",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "II Year M.Pharamacy Academic Calendar 2023-2024",
        link: "https://drive.google.com/file/d/1vx7pk_8IeSwdz4UXt3I22bO2N6bZaxn7/view?usp=sharing",
        date: "07",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "B.Tech.- R23 EEE Syllabus",
        link: "https://drive.google.com/file/d/1OibFfXjzC8lPLTaX6FYkNO50CSgePGwi/view?usp=sharing",
        date: "06",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "B.Tech.- R23 ECE Syllabus",
        link: "https://drive.google.com/file/d/1uxvCLKK9a-kFmaFqf7GvHAFjlADB8tDQ/view?usp=sharing",
        date: "06",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "B.Tech.- R23 CSE,IT Allied Courses Syllabus",
        link: "https://drive.google.com/file/d/1IbU-wp_ZJAvB0QSo_ULLDflsOrDk_1nn/view?usp=sharing",
        date: "05",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "B.Tech.- R23 MECH & Automobile Engg Syllabus",
        link: "https://drive.google.com/file/d/10fkXcjurScOBXzXqH8O6CcNae2CWVgVX/view?usp=sharing",
        date: "05",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "B.Tech.-R23 Civil Eng Syllabus",
        link: "https://drive.google.com/file/d/19iaG0va7kQo9ufHiqqKh5BCtuxLPiIzy/view?usp=sharing",
        date: "05",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "JNTUGV-UG-B.Tech.- COURSE STRUCTURE",
        link: "https://drive.google.com/file/d/1TY6bM5LSoWhA8afnfY_bnGGanz455iIv/view?usp=sharing",
        date: "05",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Pre-Ph.D Subjects List and Syllabus",
        link: "https://drive.google.com/file/d/1vyc7MGqJzS_AMSbRZjgZk2mfyWtLxInY/view?usp=sharing",
        date: "01",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Pre-Ph.D Subjects Registrartion Form",
        link: "https://drive.google.com/file/d/18pPyP9PgEUtYFSujPuXrDT-OB_JTVFDn/view?usp=sharing",
        date: "01",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Pre-Ph.D Subjects Notification",
        link: "https://drive.google.com/file/d/1j2s2Yy0vncDNiWYEyMSSHSSjy7I8nP1E/view?usp=sharing",
        date: "01",
        month: "Sep",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "M.Pharmacy  IV Sem  Project Work Viva Voce Registration Notification  September - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1693396860-69633M.pdf",
        date: "30",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Pharm D 1st Year Reg & Supply Exams Notification-October-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1693303860-70537Pharm D 1Year R&S Notification-October-2023.pdf",
        date: "29",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "TIME TABLE FOR I B. TECH I SEMESTER (R13 REGULATION) SUPPLEMENTARY EXAMINATIONS, SEPTEMBER - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1693303500-17791I B.pdf",
        date: "29",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "TIME TABLE FOR I B. TECH I SEMESTER (R16 REGULATION) SUPPLEMENTARY EXAMINATIONS, SEPTEMBER - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1693303380-96233I B.pdf",
        date: "29",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "TIME TABLE FOR I B. TECH I SEMESTER (R19 REGULATION) SUPPLEMENTARY EXAMINATIONS, SEPTEMBER - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1693303200-37440I B.pdf",
        date: "29",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "TIME TABLE FOR I B.TECH - I SEMESTER (R20 REGULATIONS) SUPPLEMENTARY EXAMINATIONS, SEPTEMBER-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1693302840-86265I B.pdf",
        date: "29",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendar for I Year B.Tech for the Academic Year 2023\u201324.",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1693046700-22646Academic Calander for I B.pdf",
        date: "26",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "M.Pharmacy -II Sem (PCI Regulation) -II MID Exam Time Table-August-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1691662080-49641M.pdf",
        date: "10",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Revised Academic Calendar for M. Tech II Year for Academic Year 2023\u201324",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1691152920-9654Revised Academic calander for M.pdf",
        date: "04",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendar for III Pharma D for the Academic Year 2023\u201324.",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1691055720-88591Academic Calander for III Pharma D  for the AY 2023-24.pdf",
        date: "03",
        month: "Aug",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Notification for B. Tech/1-I/Supply Exams (R20, R19, R16, R13), July/August -2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690451520-53835I B.pdf",
        date: "27",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Revaluation Result of I B.Tech I Semester Regsup R20 Feb 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690445820-99407Revaluation Result of I B.pdf",
        date: "27",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Revaluation results of IV B. Pharm II Semester PCI April 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690445760-25100Revaluatio results of IV B.pdf",
        date: "27",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revaluation Result of IV  BTech II SemR19,R16 April 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690445640-60571Revaluatio Result of IV  BTech II SemR19,R16 April 2023.pdf",
        date: "27",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Jumbling/Clustering Centers List for I B. Pharmacy II Sem & II B. Pharmacy I Supply Examinations, August- 2023.",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690368780-30834jumbling centers list B.pdf",
        date: "26",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic calendar fot B. Tech IV Year",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690285140-57368Academic calendar fot B.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic calendar for B. Tech III Year",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690285140-73817Academic calendar for B.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic calendar for B. Tech II Year",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690285140-39360Academic calendar for B.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calander for MCA II Year AY 2023-2",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690285080-19187Academic Calander for MCA II Year AY 2023-24.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calander for MBA II Year AY 2023-24",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690285020-13456Academic Calander for MBA II Year AY 2023-24.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calander for M. Tech II Year AY 2023-24",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690285020-94103Academic Calander for M.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calander for B.Pharmacy III Year AY 2023-24",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690284900-71900Academic Calander for B.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calender for IV and V Pharma.D",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690284840-83581Academic Calender for IV and V Pharma.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic calendar IV B.Pharmacy AY 2023-24",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690284780-41008Academic calendar IV B.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic calendar VI Pharma D AY 2023-24",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690284660-7971Academic calendar VI Pharma D AY 2023-24 (2).pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "II B. PHARMACY I SEMESTER (PCI , R16, R13) SUPPLEMENTRY  EXAMINATIONS, AUG-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690274700-97214II B.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "I B. PHARMACY II SEMESTER (PCI , R16, R13) SUPPLEMENTRY  EXAMINATIONS, AUG-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690274580-91684I B.pdf",
        date: "25",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "NSS Cell-YUVA Portal to Gain the benefit of Expreiential Learning",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690020180-19303NSS Cell YUVA Portal to Gain the benefit of Expreiential Learning.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "NSS Cell-  Call for nominations for national level NSS",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690019940-43339NSS Cell  Call for nominations for national level NSS.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic calendar for B. Tech IV Year for the Academic Year 2023-24",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690006080-94867Academic calendar for B.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic calendar for B. Tech II Year for the Academic Year 2023-24.",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690006020-6236Academic calendar for B.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendar for B. Tech III Year for the Academic Year 2023\u201324",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690005900-26390Academic calendar for B.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendar for II-Year MCA for the Academic Year 2023\u201324",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690005600-98864Academic Calander for MCA II Year AY 2023-24.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendar for the II-Year MBA for the Academic Year 2023\u201324",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690005540-38911Academic Calander for MBA II Year AY 2023-24.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendar for II-Year M. Tech for the Academic Year 2023\u201324",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690005420-60478Academic Calander for M.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendar for III Year B.Pharmacy for the Academic Year 2023-24",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1690005240-42345Academic Calander for B.pdf",
        date: "22",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "ADMISSIONS NOTIFICATION For Ph.D Program for the Academic Year 2023-2024",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689330480-1401513-07-2023-DA-Ph.pdf",
        date: "14",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "List of vacancies in Ph.D Programme in University constituent Colleges, affiliated Colleges and Research centers",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689323760-2378014-07-2023-Vacancy Position-APRCET-2023.pdf",
        date: "14",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "MCA II-Semester (R20) Regular/ Supplementary Examinations Time Table July-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689069180-78233MCA II SEM Regular supple exams july 2023 -Time table.pdf",
        date: "11",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "M.Tech II-Semester (R19) Regular/ Supplementary Examinations Time Table July-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689069120-88631M Tech  II SEM Regular supple exams july 2023 -Time table.pdf",
        date: "11",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "MBA II-Semester (R19) Regular/ Supplementary Examinations Time Table July-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689069000-15741MBA II SEM Regular supple exams july 2023 -Time table.pdf",
        date: "11",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "M.TECH,MBA&MCA-II SEM R&S LAB EXTERNAL EXAMS CIRCULAR-JULY-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689052080-32471M.pdf",
        date: "11",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "External Lab Examiners for II B.Pharmacy II Semester Regular/Supplementary Examinations, July- 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689051840-15538II B.pdf",
        date: "11",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "EXTERNAL LAB EXAMINERS FOR I B.TECH II SEMESTER REGULAR/SUPPLEMENTARY EXAMINATIONS, JULY- 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1689051540-75040I B.pdf",
        date: "11",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Notification For II B. Pharmacy I Semester Supply Exams, July - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1688472840-88667B.pdf",
        date: "04",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Notification For I B. Pharmacy II Semester Supply Exams, July - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1688472660-13301B.pdf",
        date: "04",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "CIRCULAR - Payment of Total Affiliation/Common Services dues up to 2021-2022",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1688376360-19813daa comman service fees.pdf",
        date: "03",
        month: "Jul",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calendar for IV and V Pharma.D",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1688120220-22660Academic Calender for IV and V Pharma.pdf",
        date: "30",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revised I B.Tech II sem R16 supply time table July_2023.pdf",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687954500-80891Revised I B.pdf",
        date: "28",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "II B. PHARMACY II SEMESTER (PCI , R16, R13) REGULAR / SUPPLEMENTRY EXAMINATIONS, JULY-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687851120-68574II B.pdf",
        date: "27",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "I B.TECH II SEMESTER (R13 REGULATION) SUPPLEMENTARY EXAMINATIONS, JULY - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687851060-55549I B.pdf",
        date: "27",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "I B. TECH II SEMESTER (R19 REGULATION) SUPPLEMENTARY EXAMINATIONS, JULY- 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687850940-59950I B.pdf",
        date: "27",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "I B TECH - II SEMESTER (R20 REGULATIONS) REGULAR/SUPPLEMENTRARY END EXAMINATIONS, JULY-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687850880-66061I B.pdf",
        date: "27",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Circular: Research Scholars Meeting on 28-06-2023 is Postponed.",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687522140-26521Circular-Research Scholars Meeting on 28-06-2023 is Postponed.pdf",
        date: "23",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Conduct of Inspection for grant of affiliation(Temporary/Permanent) under virtual mode for the AY 2023-24 of all affiliated colleges-Circular",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687260960-42782circular ffc.pdf",
        date: "20",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "JNTUGV- R&D-2022 Admitted Research Scholars-Circular-17-06-2023.",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687013280-43684JNTUGV-DR&D-2022 Admitted Research Scholars-Circular-17-06-2023.pdf",
        date: "17",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "JNTUGV-R&D-Opted Research Scholars-Circular-17-06-2023.",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1687013160-25328JNTUGV-DR&D-Opted Research Scholars-Circular-17-06-2023.pdf",
        date: "17",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "A fifteen day online faculty development programme on Emerging Research Trends in Computer Science & Engineering on 26/06/2023 to 10/07/2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686916080-66312a fifteen day online faculty development programme onEmerging Research Trends in Computer Science &Engineering.pdf",
        date: "16",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Clearance of affiliation/common service dues (50%) upto 2021-2022",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686564180-19151fee dues 50%.pdf",
        date: "12",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calender for VI Year-Pharm. D for the Academic Year 2023-2024",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686377940-12848Academic calendar VI Pharma D AY 2023-24.pdf",
        date: "10",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Academic Calendrer for IV B. Pharmacy for the academic Year 2023-2024",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686377520-44251Academic calendar IV B.pdf",
        date: "10",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "III B. PHARMACY I SEMESTER (PCI & R16 REGULATION) SUPPLEMENTARY EXAMINATIONS TIME TABLE, JUNE - 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686207840-87981III B.pdf",
        date: "08",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revised Time table II B.Tech I Sem R16 Supply June-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686119040-16793Revised II.pdf",
        date: "07",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revised B.Tech II Year I Sem R19 Supply Time Table June 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686118860-27282II.pdf",
        date: "07",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revised III B.Tech I Sem R16 Supply June 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1686041760-51327R16 III B.pdf",
        date: "06",
        month: "Jun",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Revised Academic Calender for B.Tech III Year-II Semester for the Academic Year 2022-23",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1685174940-88203Revised Academic Calender for B.pdf",
        date: "27",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Revised Academic Calender for B.Tech II Year-II Semester for the Academic Year 2022-23",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1685174760-98641Revised Academic Calender for B.pdf",
        date: "27",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Revised Academic Calender for B.Tech I Year-II Semester for the Academic Year 2022-23",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1685174700-16364Revised Academic Calender for B.pdf",
        date: "27",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "MBA -II Sem Regular and Supplementary Exam Notification July- 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1685171760-56211MBA -II Sem R&S Exam Notification July- 2023.pdf",
        date: "27",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "M.Tech -II Sem Regular-Supplementary Examination Notification July- 2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1685171640-82825M.pdf",
        date: "27",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "The University Examination, III B.Pharmacy II Sem. Reg./Supple held on 22-05-2023 (Monday) is rescheduled",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1684494600-52237Circular (2).pdf",
        date: "19",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revised II B.Tech II SEM(19) supply time table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1684214460-33197II B.pdf",
        date: "16",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "II B.Tech II SEM(R13) supply time table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1684151280-57911II B.pdf",
        date: "15",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "II B.Tech II SEM(R16) supply time table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1684151160-13464II B.pdf",
        date: "15",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "II B.Tech II SEM(R20) regular supply time table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1684151100-17562II B.pdf",
        date: "15",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Academic Calendrer for II, III, IV, V, Year Pharma D",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683883140-91956Academic Calander for II,III,IV,V,Year Pharma D.pdf",
        date: "12",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "III B.Pharmacy II Sem Reg Supply Examinations May 2023 Time Table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683552900-809303-2 B.pdf",
        date: "08",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "Revised I B. PHARMACY I SEM Reg Suppl Examinations May 2023 TIME TABLE",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683548220-31609I.pdf",
        date: "08",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "III B.Tech-II Sem R16 Suppl May 2023 Time Table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683536220-481863-2 R16 Suppl Time Table.pdf",
        date: "08",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "III B.Tech II Sem R13 Suppl May-2023 Time Table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683536220-230013-2 R13 Suppl Time Table.pdf",
        date: "08",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "III B.Tech II Sem R19 Suppl May-2023 Time Table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683536160-41653-2 R19 Suppl Time Table.pdf",
        date: "08",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "III B.Tech II Sem R20 Regular Suppl May 2023 Time Table",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683536100-286883-2 R20 Regular Suppl Time Table.pdf",
        date: "08",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Pharm D V Year -R&S Exams Time Table-MAy-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683290220-96118Pharm D V Year -R&S Exams Time Table-MAy-2023.pdf",
        date: "05",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Pharm D IV Year -R&S Exams Time Table-May-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683290160-22200Pharm D IV Year -R&S Exams Time Table-May-2023.pdf",
        date: "05",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Pharm D III Year -R&S Exams Time Table-May-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1683289980-49249Pharm D III Year -R&S Exams Time Table-May-2023.pdf",
        date: "05",
        month: "May",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description:
          "JNTUGV-DR&D-2022 Admitted Research Scholars-Circular-29-04-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1682776740-4578JNTUGV-DR&D-2022 Admitted Research Scholars-Circular-29-04-2023.pdf",
        date: "29",
        month: "Apr",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revised Pharm D IInd Year Supple Time Table-April/May-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1681899000-55725Revised Pharm D II nd Year Supple Time Table-April&May-2023.pdf",
        date: "19",
        month: "Apr",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
      {
        description: "Revised Pharm D Ist Year Supple Time Table-April/May-2023",
        link: "https://jntugv.edu.in/updates/9266a0f9fc226ffbf57a3beb4c6b6388/notifications/1681898880-61594Revised Pharm D I st Year Supple Time Table-April&May-2023.pdf",
        date: "19",
        month: "Apr",
        year: "2023",
        type: "notifications",
        isLink: true,
      },
  ];
  