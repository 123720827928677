const seventeenData = [
    {
      sno: 1,
      companyName: 'Apps Associates',
      btechCSE: 2,
      btechIT: 3,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 5,
    },
    {
      sno: 2,
      companyName: 'Bhanu Special Casting Pvt.Ltd',
      btechCSE: '-',
      btechIT: '-',
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '2',
      onOffCampus: '-',
      totalSelections: 2,
    },
    {
      sno: 3,
      companyName: 'Efftronics',
      btechCSE: 1,
      btechIT: 1,
      btechECE: 1,
      btechEEE: 2,
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 5,
    },
    {
      sno: 4,
      companyName: 'Greycampus',
      btechCSE: 2,
      btechIT: 4,
      btechECE: '-',
      btechEEE: 2,
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 7,
    },
    {
      sno: 5,
      companyName: 'Miracle',
      btechCSE: 11,
      btechIT: 9,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 20,
    },
    {
      sno: 6,
      companyName: 'Soctronics',
      btechCSE: 7,
      btechIT: '-',
      btechECE: 8,
      btechEEE: 5,
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 20,
    },
    {
      sno: 7,
      companyName: 'TCS',
      btechCSE: 2,
      btechIT: 3,
      btechECE: 6,
      btechEEE: '-',
      btechMECH: 2,
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 13,
    },
    {
      sno: 8,
      companyName: 'Uurmi',
      btechCSE: 1,
      btechIT: '-',
      btechECE: 3,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 4,
    },
    {
      sno: 9,
      companyName: 'Suneratech',
      btechCSE: 4,
      btechIT: 2,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 6,
    },
    {
      sno: 10,
      companyName: 'Cyient',
      btechCSE: 3,
      btechIT: '-',
      btechECE: '-',
      btechEEE: '-',
      btechMECH: 1,
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 5,
    },
    {
      sno: 11,
      companyName: 'Amazon',
      btechCSE: '-',
      btechIT: 3,
      btechECE: 1,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 4,
    },
    {
      sno: 12,
      companyName: 'Airtel',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 1,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 1,
    },
    {
      sno: 13,
      companyName: 'Zebi',
      btechCSE: 1,
      btechIT: 1,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 2,
    },
    {
      sno: 14,
      companyName: 'Genpact',
      btechCSE: '-',
      btechIT: 1,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 1,
    },
    {
      sno: 15,
      companyName: 'Data India Pvt.Ltd',
      btechCSE: '-',
      btechIT: 1,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 1,
    },
    {
      sno: 16,
      companyName: 'Transcend Solutions',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 1,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 1,
    },
    {
      sno: 17,
      companyName: 'Drdo',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 2,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 2,
    },
    {
      sno: 18,
      companyName: 'Teradata',
      btechCSE: 1,
      btechIT: '-',
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      mtechMECH: '-',
      onOffCampus: '-',
      totalSelections: 1,
    },
    {
      sno: 19,
      companyName: 'Medha servo drives pvt ltd',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 1,
      btechEEE: 2,
      btechMECH: 1,
      btechMET: '-',
      mtechMECH: '1',
      onOffCampus: '-',
      totalSelections: 5,
    },
  ];
  
  export default seventeenData;
  