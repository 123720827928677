const twentythreeData = [
    {
      sno: 1,
      companyName: 'Cognizant Pvt.Ltd.',
      btechCSE: 14,
      btechIT: 4,
      btechECE: 12,
      btechEEE: 9,
      btechMECH: 2,
      btechMET: 2,
      btechCIVIL: 2,
      mca: '-',
      totalSelections: 45,
      packages: '4.5 lpa',
    },
    {
      sno: 2,
      companyName: 'Tech Mahindra Pvt.Ltd.',
      btechCSE: 4,
      btechIT: 3,
      btechECE: 2,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 9,
      packages: '3.25 to 5.5 lpa',
    },
    {
      sno: 3,
      companyName: 'Cyient Pvt.Ltd.',
      btechCSE: '-',
      btechIT: '-',
      btechECE: '-',
      btechEEE: 7,
      btechMECH: 11,
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 18,
      packages: '4.2 lpa',
    },
    {
      sno: 4,
      companyName: 'L & T',
      btechCSE: 10,
      btechIT: '-',
      btechECE: 3,
      btechEEE: 2,
      btechMECH: 10,
      btechMET: 1,
      btechCIVIL: 4,
      mca: '-',
      totalSelections: 30,
      packages: '6 to 6.5 lpa',
    },
    {
      sno: 5,
      companyName: 'Medha Servo Pvt.Ltd',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 1,
      btechEEE: 1,
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 2,
      packages: '4 to 6 lpa',
    },
    {
      sno: 6,
      companyName: 'Tata Consultancy Services',
      btechCSE: 2,
      btechIT: 7,
      btechECE: 4,
      btechEEE: '-',
      btechMECH: 3,
      btechMET: 1,
      btechCIVIL: 1,
      mca: 1,
      totalSelections: 19,
      packages: '3.5 to 7.5 lpa',
    },
    {
      sno: 7,
      companyName: 'Moschip Technologies Pvt.Ltd',
      btechCSE: '-',
      btechIT: '-',
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 0,
      packages: '6 lpa',
    },
    {
      sno: 8,
      companyName: 'Apxor',
      btechCSE: '-',
      btechIT: '-',
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 0,
      packages: '3.5 to 6 lpa',
    },
    {
      sno: 9,
      companyName: 'Nalsoft Pvt.Ltd.',
      btechCSE: '-',
      btechIT: '-',
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 0,
      packages: '3.5 to 6 lpa',
    },
    {
      sno: 10,
      companyName: 'VEDA IIT',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 1,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 1,
      packages: '6 to 9 lpa',
    },
    {
      sno: 11,
      companyName: 'Westline Shipping',
      btechCSE: '-',
      btechIT: 2,
      btechECE: 3,
      btechEEE: 2,
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 7,
      packages: '19 to 24 lpa',
    },
    {
      sno: 12,
      companyName: 'SmartsoC Solutions Pvt.Ltd.,',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 1,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 1,
      packages: '4.5 lpa',
    },
    {
      sno: 13,
      companyName: 'Byjus',
      btechCSE: 2,
      btechIT: 6,
      btechECE: 2,
      btechEEE: 2,
      btechMECH: 3,
      btechMET: 2,
      btechCIVIL: 1,
      mca: '-',
      totalSelections: 18,
      packages: '7 lpa',
    },
    {
      sno: 14,
      companyName: 'Pentagon Space Pvt Ltd',
      btechCSE: 1,
      btechIT: 2,
      btechECE: 1,
      btechEEE: 1,
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 5,
      packages: '3 to 12 lpa',
    },
    {
      sno: 15,
      companyName: 'NCC Ltd',
      btechCSE: '-',
      btechIT: '-',
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: 2,
      totalSelections: 2,
      packages: '3 to 3.6 lpa',
    },
    {
      sno: 16,
      companyName: 'SoCtronic',
      btechCSE: '-',
      btechIT: '-',
      btechECE: 4,
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 4,
      packages: '3.6 to 4.2 lpa',
    },
    {
      sno: 17,
      companyName: 'CONIODO Software\'s America',
      btechCSE: '-',
      btechIT: 1,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 1,
      packages: '30 lpa',
    },
    {
      sno: 18,
      companyName: 'Pennant Technologies Pvt.Ltd.',
      btechCSE: 7,
      btechIT: 1,
      btechECE: '-',
      btechEEE: '-',
      btechMECH: '-',
      btechMET: '-',
      btechCIVIL: '-',
      mca: '-',
      totalSelections: 8,
      packages: '4 lpa',
    },
    {
      sno: 19,
      companyName: 'Total',
      btechCSE: 40,
      btechIT: 29,
      btechECE: 34,
      btechEEE: 24,
      btechMECH: 29,
      btechMET: 6,
      btechCIVIL: 10,
      mca: 1,
      totalSelections: 173,
      packages: '',
    },
  ];
  
  export default twentythreeData;
  