// TwentyfourData.js
const placementData = [
    {
      id: 1,
      companyName: "Moschip Technologies",
      recruitmentDate: "21/22/Sep/2023",
      cse: "-",
      it: "-",
      ece: "1",
      eee: "-",
      mech: "-",
      met: "-",
      civil: "-",
      te: "-",
      ssp: "-",
      ds: "-",
      adps: "-",
      mst: "-",
      mca: "-",
      totalSelects: "1",
      packages: "6 LPA",
      sector: "NON IT",
    },
    {
      id: 2,
      companyName: "L&T Constructions",
      recruitmentDate: "24-Sep-23",
      cse: "-",
      it: "-",
      ece: "-",
      eee: "-",
      mech: "22",
      met: "-",
      civil: "4",
      te: "-",
      ssp: "-",
      ds: "-",
      adps: "-",
      mst: "-",
      mca: "-",
      totalSelects: "26",
      packages: "6 LPA to 6.25 LPA",
      sector: "NON IT",
    },
    {
      id: 3,
      companyName: "Pennant Technologies",
      recruitmentDate: "27-Nov-23",
      cse: "10",
      it: "7",
      ece: "1",
      eee: "-",
      mech: "-",
      met: "-",
      civil: "-",
      te: "-",
      ssp: "-",
      ds: "-",
      adps: "-",
      mst: "-",
      mca: "-",
      totalSelects: "18",
      packages: "4 LPA",
      sector: "IT",
    },
    {
      id: 4,
      companyName: "CYIENT Ltd",
      recruitmentDate: "-",
      cse: "-",
      it: "-",
      ece: "-",
      eee: "-",
      mech: "9",
      met: "-",
      civil: "-",
      te: "-",
      ssp: "-",
      ds: "-",
      adps: "-",
      mst: "-",
      mca: "-",
      totalSelects: "9",
      packages: "4.2 LPA",
      sector: "NON IT",
    },
    {
      id: 5,
      companyName: "MEDHA SERVO DRIVES",
      recruitmentDate: "-",
      cse: "-",
      it: "-",
      ece: "-",
      eee: "1",
      mech: "-",
      met: "-",
      civil: "-",
      te: "-",
      ssp: "-",
      ds: "-",
      adps: "-",
      mst: "-",
      mca: "-",
      totalSelects: "1",
      packages: "4 LPA",
      sector: "NON IT",
    },

    {
      id: 6,
      companyName: "EFFTRONICS SYSTEMS PVT.LTD",
      recruitmentDate: "-",
      cse: "3",
      it: "-",
      ece: "-",
      eee: "-",
      mech: "-",
      met: "-",
      civil: "-",
      te: "-",
      ssp: "-",
      ds: "-",
      adps: "-",
      mst: "-",
      mca: "-",
      totalSelects: "3",
      packages: "5 LPA",
      sector: "IT",
    },

    {
      id: 7,
      companyName: "Drishya AI Labs",
      recruitmentDate: "-",
      cse: "-",
      it: "4",
      ece: "-",
      eee: "-",
      mech: "-",
      met: "-",
      civil: "-",
      te: "-",
      ssp: "-",
      ds: "-",
      adps: "-",
      mst: "-",
      mca: "-",
      totalSelects: "4",
      packages: "7 LPA",
      sector: "IT",
    },
  ];
  
  export default placementData;
  