const studentsPlacedData = [
    {
      sno: 1,
      name: "BALLA SRI SATYA ABHISHEK",
      rollnumber: "18VV1A0202",
      branch: "EEE",
      passingyear: "2021-2022",
      campus: "On Campus (Virtual)",
      company: "Byjus",
    },
    {
      sno: 2,
      name: 'NIVEDITA BOYINA',
      rollnumber: '18VV1A0205',
      branch: 'EEE',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Cognizant Pvt Ltd',
      },
      {
      sno: 3,
      name: 'BOYINA SAI POORNIMA',
      rollnumber: '18VV1A0206',
      branch: 'EEE',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Pentagon Space CSR',
      },
      {
      sno: 4,
      name: 'G S BHEEMESHWARACHARYULU',
      rollnumber: '18VV1A0210',
      branch: 'EEE',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'L&T Constructions',
      },
      {
      sno: 5,
      name: 'KUMAR DURGA MANOHAR',
      rollnumber: '18VV1A0219',
      branch: 'EEE',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Infosys',
      },
      {
      sno: 6,
      name: 'LOCHERLA.LAVANYA',
      rollnumber: '18VV1A0222',
      branch: 'EEE',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Tilicho Labs',
      },
      {
      sno: 7,
      name: 'MADEM SRUJANA',
      rollnumber: '18VV1A0224',
      branch: 'EEE',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'CGI',
      },
      {
      sno: 8,
      name: 'MATTA KUSUMA',
      rollnumber: '18VV1A0225',
      branch: 'EEE',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Wipro',
      },
      {
        sno: 9,
        name: 'MOHAMMED FAIZ NAWAZ',
        rollnumber: '18VV1A0226',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd',
        },
        {
        sno: 10,
        name: 'MUBARAKA BEGUM',
        rollnumber: '18VV1A0228',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Pentagon Space CSR',
        },
        {
        sno: 11,
        name: 'N.JYOTHSNA',
        rollnumber: '18VV1A0231',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd',
        },
        {
        sno: 12,
        name: 'NOTLA TEJASWI',
        rollnumber: '18VV1A0234',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI',
        },
        {
        sno: 13,
        name: 'ORUGANTI ANJAN KUMAR',
        rollnumber: '18VV1A0235',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Infor',
        },
        {
        sno: 14,
        name: 'SAI TEJA VOOTAPALLI',
        rollnumber: '18VV1A0239',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 15,
        name: 'SIDDHANTAM SAI SRI SANTHOSHI SHIVANI',
        rollnumber: '18VV1A0240',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro',
        },
        {
        sno: 16,
        name: 'SIMMA NAVEEN',
        rollnumber: '18VV1A0242',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Infor',
        },
        {
        sno: 17,
        name: 'SRIVALLI NAKKA',
        rollnumber: '18VV1A0243',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd',
        },
        {
        sno: 18,
        name: 'SD.NAGA NIRANJANI',
        rollnumber: '18VV1A0244',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro',
        },
        {
        sno: 19,
        name: 'BATRAJU VEERANJANEYULU',
        rollnumber: '19VV5A0263',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro',
        },
        {
        sno: 20,
        name: 'K LAKSHMAN',
        rollnumber: '19VV5A0269',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd',
        },
        {
        sno: 21,
        name: 'KONDAPALLI.CHINNA',
        rollnumber: '19VV5A0271',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 22,
        name: 'MANDAPATI RAJESH',
        rollnumber: '19VV5A0272',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'IMEG',
        },
        {
        sno: 23,
        name: 'MEDISETTI SATYA RAJESWARI',
        rollnumber: '19VV5A0273',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'IMEG',
        },
        {
        sno: 24,
        name: 'YADLA GANESH',
        rollnumber: '19VV5A0280',
        branch: 'EEE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Hexaware',
        },
        {
        sno: 25,
        name: 'ALAM HARSHA VARDHAN',
        rollnumber: '18VV1A0302',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro',
        },
        {
        sno: 26,
        name: 'BANKA UDAY KIRAN',
        rollnumber: '18VV1A0304',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Srikalahasti Pipes Ltd',
        },
        {
        sno: 27,
        name: 'D S D PRASANTH',
        rollnumber: '18VV1A0308',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Srikalahasti Pipes Ltd',
        },
        {
        sno: 28,
        name: 'INDLA KARTHIK VIHAR',
        rollnumber: '18VV1A0312',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'IMEG',
        },
        {
        sno: 29,
        name: 'INDURI PAVAN KUMAR',
        rollnumber: '18VV1A0313',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro',
        },
        {
        sno: 30,
        name: 'JYOTSNAVI KARINKI',
        rollnumber: '18VV1A0317',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 31,
        name: 'KATARI VAMSI',
        rollnumber: '18VV1A0318',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Srikalahasti Pipes Ltd',
        },
        {
        sno: 32,
        name: 'KUNKUNURI MALLIKARJUNA RAO',
        rollnumber: '18VV1A0324',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI',
        },
        {
        sno: 33,
        name: 'LALAM PRASANTH',
        rollnumber: '18VV1A0325',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Srikalahasti Pipes Ltd',
        },
        {
        sno: 34,
        name: 'MADEVU VAMSI',
        rollnumber: '18VV1A0327',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 35,
        name: 'NEEHARIKA KARTHEKA',
        rollnumber: '18VV1A0331',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CSS Corp',
        },
        {
        sno: 36,
        name: 'SK TAJ ARSHAD MOHAMMAD',
        rollnumber: '18VV1A0338',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd',
        },
        {
        sno: 37,
        name: 'SIDAGAM REVANTH KUMAR',
        rollnumber: '18VV1A0339',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Byjus',
        },
        {
        sno: 38,
        name: 'SIDAGAM ROHIT',
        rollnumber: '18VV1A0340',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Medha Servo',
        },
        {
        sno: 39,
        name: 'SURU BHARATH KUMAR',
        rollnumber: '18VV1A0341',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro',
        },
        {
        sno: 40,
        name: 'VENKATA LAKSHMI MANAPURAM',
        rollnumber: '18VV1A0345',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 41,
        name: 'MATTAPARTHI RUSHIKESH',
        rollnumber: '19VV1A0369',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'TCS,Wipro',
        },
        {
        sno: 42,
        name: 'NARALASETTY PRASAD',
        rollnumber: '19VV5A0371',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 43,
        name: 'CHANDU DURGA PRASAD',
        rollnumber: '19VV5A0380',
        branch: 'MECH',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Pentagon Space CSR',
        },
        {
        sno: 44,
        name: 'ANCHI SAI ADARSH PAVAN KUMAR',
        rollnumber: '18VV1A0401',
        branch: 'ECE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 45,
        name: 'BANAVATH VENKATA KRISHNA SAI',
        rollnumber: '18VV1A0402',
        branch: 'ECE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 46,
        name: 'PAVAN KUMAR BUDITI',
        rollnumber: '18VV1A0403',
        branch: 'ECE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd',
        },
        {
        sno: 47,
        name: 'CH SUPRIYA',
        rollnumber: '18VV1A0406',
        branch: 'ECE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions',
        },
        {
        sno: 48,
        name: 'CHINTALA SIVA KRISHNA',
        rollnumber: '18VV1A0407',
        branch: 'ECE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro',
        },
        {
        sno: 49,
        name: 'CHIPPADA HARSHINI',
        rollnumber: '18VV1A0408',
        branch: 'ECE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Accenture',
        },
        {
        sno: 50,
        name: 'DEEPIKA MEESALA',
        rollnumber: '18VV1A0410',
        branch: 'ECE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd',
        },
        {
          sno: 51,
          name: 'DHARANIKOTA NETHRINI',
          rollnumber: '18VV1A0411',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'CGI',
      },
      {
          sno: 52,
          name: 'DURVASULA SREE ARCHANA',
          rollnumber: '18VV1A0414',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Infor',
      },
      {
          sno: 53,
          name: 'GEDELA MADHU BABU',
          rollnumber: '18VV1A0416',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 54,
          name: 'IMANDI VANAJA',
          rollnumber: '18VV1A0418',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'CGI',
      },
      {
          sno: 55,
          name: 'KARRI KAMALA PRIYA',
          rollnumber: '18VV1A0419',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Accenture',
      },
      {
          sno: 56,
          name: 'KUDUPUDI VARSHITHA',
          rollnumber: '18VV1A0421',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Wipro',
      },
      {
          sno: 57,
          name: 'LUVVABU MANJULA',
          rollnumber: '18VV1A0422',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 58,
          name: 'BHARGAVA JYOTHI',
          rollnumber: '18VV1A0423',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Accenture',
      },
      {
          sno: 59,
          name: 'MAVURI SURYA MANI TEJASWI',
          rollnumber: '18VV1A0424',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Infor',
      },
      {
          sno: 60,
          name: 'MOPADA RAKESH NAIDU',
          rollnumber: '18VV1A0425',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Infor',
      },
      {
          sno: 61,
          name: 'MORLA VENKATESH',
          rollnumber: '18VV1A0426',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Wipro',
      },
      {
          sno: 62,
          name: 'NANDAM SAI PRITHAM',
          rollnumber: '18VV1A0429',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'CGI',
      },
      {
          sno: 63,
          name: 'MANI KRISHNA KRISHNA PATCHA',
          rollnumber: '18VV1A0431',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cognizant Pvt Ltd',
      },
      {
          sno: 64,
          name: 'POTNURU MANIVARMA',
          rollnumber: '18VV1A0434',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 65,
          name: 'PRATHYUSHA BABBURU',
          rollnumber: '18VV1A0435',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'CGI',
      },
      {
          sno: 66,
          name: 'SANAPALA SAISURESH',
          rollnumber: '18VV1A0436',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 67,
          name: 'SATHAKA SUJALATHA',
          rollnumber: '18VV1A0437',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 68,
          name: 'SHAIK ANWAR BASHA',
          rollnumber: '18VV1A0438',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'TCS',
      },
      {
          sno: 69,
          name: 'SHAIK MUKTHUM HUSSAIN',
          rollnumber: '18VV1A0440',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'CGI',
      },
      {
          sno: 70,
          name: 'SINGURI PHANEENDRA',
          rollnumber: '18VV1A0442',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Leoforce',
      },
      {
          sno: 71,
          name: 'SRINIVASU SALADI',
          rollnumber: '18VV1A0443',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 72,
          name: 'V NAGA MANI KANTA GANESH',
          rollnumber: '18VV1A0444',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 73,
          name: 'VANGAPANDU NIKHILESH',
          rollnumber: '18VV1A0445',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Wipro',
      },
      {
          sno: 74,
          name: 'VYKUNTHAM MOUNIKA',
          rollnumber: '18VV1A0446',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 75,
          name: 'RATHAN SATHWIK YENDLURI',
          rollnumber: '18VV1A0447',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Accenture',
      },
      {
          sno: 76,
          name: 'CHALUMURI APPALA NAIDU',
          rollnumber: '19VV5A0461',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus',
          company: 'Accenture',
      },
      {
          sno: 77,
          name: 'DODDI HEMALATHA',
          rollnumber: '19VV5A0462',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Leoforce',
      },
      {
          sno: 78,
          name: 'ELLINKI GAYATRI',
          rollnumber: '19VV5A0463',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Pentagon Space CSR',
      },
      {
          sno: 79,
          name: 'GUMMALLA HYMADEVI',
          rollnumber: '19VV5A0464',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 80,
          name: 'M. SHARMILA',
          rollnumber: '19VV5A0466',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'L&T Constructions',
      },
      {
          sno: 81,
          name: 'PENKE RAMYA SRI',
          rollnumber: '19VV5A0468',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus',
          company: 'KelltonTech Pvt Ltd',
      },
      {
          sno: 82,
          name: 'PERAKA JAHNAVI',
          rollnumber: '19VV5A0469',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus',
          company: 'KelltonTech Pvt Ltd',
      },
      {
          sno: 83,
          name: 'P SANYA SUPRIYA DURGA',
          rollnumber: '19VV5A0470',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cognizant Pvt Ltd',
      },
      {
          sno: 84,
          name: 'ROOPALATHA SABBAVARAPU',
          rollnumber: '19VV5A0472',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cognizant Pvt Ltd',
      },
      {
          sno: 85,
          name: 'SAI SWAROOPA KILAPARTHY',
          rollnumber: '19VV5A0473',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'IBM',
      },
      {
          sno: 86,
          name: 'SHAIK MAHAMMAD KHASIM',
          rollnumber: '19VV5A0474',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cognizant Pvt Ltd',
      },
      {
          sno: 87,
          name: 'SUDHAGANI KEERTANA',
          rollnumber: '19VV5A0475',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Wipro',
      },
      {
          sno: 88,
          name: 'SURALA DHARANI',
          rollnumber: '19VV5A0476',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'TCS',
      },
      {
          sno: 89,
          name: 'TAMMINA HEMA NAGA SAI SUDHA',
          rollnumber: '19VV5A0477',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'TCS,Wipro',
      },
      {
          sno: 90,
          name: 'KRISHNA TEJA VARASALA',
          rollnumber: '19VV5A0478',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cerium Systems',
      },
      {
          sno: 91,
          name: 'VEMALA SRAVANI',
          rollnumber: '19VV5A0479',
          branch: 'ECE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Pentagon Space CSR',
      },
      {
          sno: 92,
          name: 'BANTU JOHN VEDANTH RAJ',
          rollnumber: '18VV1A0502',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus',
          company: 'KelltonTech Pvt Ltd',
      },
      {
          sno: 93,
          name: 'BHUMIREDDI CHIRANJEEVI SAI KUMAR',
          rollnumber: '18VV1A0503',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Hexaware',
      },
      {
          sno: 94,
          name: 'BODDU LAKSHMI AISHWARYA',
          rollnumber: '18VV1A0504',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Accenture',
      },
      {
          sno: 95,
          name: 'SURESH RAMANA BODEMPUDI',
          rollnumber: '18VV1A0505',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cognizant Pvt Ltd'
      },
      {
          sno: 96,
          name: 'BABY PRATHYUSHA DAMARASINGI',
          rollnumber: '18VV1A0507',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Accenture'
      },
      {
          sno: 97,
          name: 'ELLIPILLI GEETIKA',
          rollnumber: '18VV1A0508',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Infor'
      },
      {
          sno: 98,
          name: 'GANAPAVARAPU SWATHI SREE',
          rollnumber: '18VV1A0509',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'IBM'
      },
      {
          sno: 99,
          name: 'GORIJELLI SAMEERA VIGHNESWARI',
          rollnumber: '18VV1A0511',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cognizant Pvt Ltd'
      },
      {
          sno: 100,
          name: 'KARI SANDHYA KUMARI',
          rollnumber: '18VV1A0512',
          branch: 'CSE',
          passingyear: '2021-2022',
          campus: 'On Campus (Virtual)',
          company: 'Cognizant Pvt Ltd'
      },
      {
        sno: 101,
        name: 'GIRISH KUMAR',
        rollnumber: '18VV1A0513',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 102,
        name: 'SIVA KESAVA KOPPINEEDI',
        rollnumber: '18VV1A0514',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Infor'
    },
    {
        sno: 103,
        name: 'ANJANI MOUNYA SRI LAKSHMIVARAPU',
        rollnumber: '18VV1A0516',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 104,
        name: 'RAVI TEJA MALLA',
        rollnumber: '18VV1A0517',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 105,
        name: 'MOGGA VEERAMANOHAR',
        rollnumber: '18VV1A0518',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 106,
        name: 'TANZILA SULTHANA',
        rollnumber: '18VV1A0519',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions'
    },
    {
        sno: 107,
        name: 'TULASI PRASAD MUDILA',
        rollnumber: '18VV1A0520',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Accenture'
    },
    {
        sno: 108,
        name: 'SIDDHARTHA NEYAGAPULA',
        rollnumber: '18VV1A0522',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Accenture'
    },
    {
        sno: 109,
        name: 'PACHILLA SHWETA',
        rollnumber: '18VV1A0523',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus',
        company: 'KelltonTech Pvt Ltd'
    },
    {
        sno: 110,
        name: 'PALAVALASA SAI KEERTHANA',
        rollnumber: '18VV1A0524',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions'
    },
    {
        sno: 111,
        name: 'PALTHIYA RAGHAVENDRA',
        rollnumber: '18VV1A0525',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 112,
        name: 'POTNURU AYYAPPA',
        rollnumber: '18VV1A0527',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'Off Campus',
        company: 'Airbus'
    },
    {
        sno: 113,
        name: 'PRUDHVI YADAV PULARI',
        rollnumber: '18VV1A0528',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 114,
        name: 'RAMYA SUNKAVALLI',
        rollnumber: '18VV1A0529',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Infor'
    },
    {
        sno: 115,
        name: 'LAXMI PRASANNA KUMAR NAIDU REDDI',
        rollnumber: '18VV1A0530',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Accenture'
    },
    {
        sno: 116,
        name: 'SHAIK MOHAMMAD MUZEEB',
        rollnumber: '18VV1A0531',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Infor'
    },
    {
        sno: 117,
        name: 'SHAIK SAADHIQH',
        rollnumber: '18VV1A0532',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'L&T Constructions'
    },
    {
        sno: 118,
        name: 'Y.KIRAN CHAND',
        rollnumber: '18VV1A0534',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro'
    },
    {
        sno: 119,
        name: 'BANDLA HIMAJA',
        rollnumber: '19VV5A0562',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI'
    },
    {
        sno: 120,
        name: 'SAI LOKESH BEERAKA',
        rollnumber: '19VV5A0563',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 121,
        name: 'BALU BONAM',
        rollnumber: '19VV5A0564',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 122,
        name: 'GANTA JAYA RAMYA',
        rollnumber: '19VV5A0568',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI'
    },
    {
        sno: 123,
        name: 'GODIA BHAVANA',
        rollnumber: '19VV5A0569',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro'
    },
    {
        sno: 124,
        name: 'G NAGA VEERA KRISHNA MEERA SWAMY',
        rollnumber: '19VV5A0570',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'TCS'
    },
    {
        sno: 125,
        name: 'GURRAM MADHURI VIJAYASRI',
        rollnumber: '19VV5A0571',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI'
    },
    {
        sno: 126,
        name: 'KALYAN BABU ISSAKOTI',
        rollnumber: '19VV5A0572',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro'
    },
    {
        sno: 127,
        name: 'JEERU SURYA VENKATA CHANDRIKA',
        rollnumber: '19VV5A0573',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI'
    },
    {
        sno: 128,
        name: 'KADALI RITHIK',
        rollnumber: '19VV5A0574',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro'
    },
    {
        sno: 129,
        name: 'KOSURU NANDINI',
        rollnumber: '19VV5A0578',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Accenture'
    },
    {
        sno: 130,
        name: 'MALLA DEEPIKA',
        rollnumber: '19VV5A0579',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI'
    },
    {
        sno: 131,
        name: 'PADALA YUVARAJ',
        rollnumber: '19VV5A0581',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'CGI'
    },
    {
        sno: 132,
        name: 'PAILA KEERTHANA',
        rollnumber: '19VV5A0582',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 133,
        name: 'PAMULAPATI DIVYA',
        rollnumber: '19VV5A0583',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Infor'
    },
    {
        sno: 134,
        name: 'P. KIRANMAYEE',
        rollnumber: '19VV5A0584',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'TCS'
    },
    {
        sno: 135,
        name: 'PECHETTI LALITHA DEVI',
        rollnumber: '19VV5A0586',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'TCS'
    },
    {
        sno: 136,
        name: 'PAVAN SAI POTNURU',
        rollnumber: '19VV5A0588',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 137,
        name: 'JAGADEESH RELLI',
        rollnumber: '19VV5A0589',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 138,
        name: 'SAI KRISHNA SEELAM',
        rollnumber: '18VV5A0590',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 139,
        name: 'SHAIK SAMEERA',
        rollnumber: '19VV5A0591',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Innominds'
    },
    {
        sno: 140,
        name: 'PAVANISAI YELLAPU',
        rollnumber: '19VV5A0592',
        branch: 'CSE',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Accenture'
    },
    {
        sno: 141,
        name: 'ALLADA SEKHAR',
        rollnumber: '18VV1A1201',
        branch: 'IT',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Wipro'
    },
    {
        sno: 142,
        name: 'BHARATH ANKABATTULA',
        rollnumber: '18VV1A1202',
        branch: 'IT',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Cognizant Pvt Ltd'
    },
    {
        sno: 143,
        name: 'BANGARI PRAVEEN PETER',
        rollnumber: '18VV1A1204',
        branch: 'IT',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'TCS'
    },
    {
        sno: 144,
        name: 'YASASWANI BARATAM',
        rollnumber: '18VV1A1205',
        branch: 'IT',
        passingyear: '2021-2022',
        campus: 'On Campus (Virtual)',
        company: 'Accenture'
    },
    {
      sno: 145,
      name: 'BOKKA SOWMYA SREE',
      rollnumber: '18VV1A1207',
      branch: 'IT',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'TCS'
  },
  {
      sno: 146,
      name: 'BONAM YASASWINI',
      rollnumber: '18VV1A1208',
      branch: 'IT',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'IBM'
  },
  {
      sno: 147,
      name: 'BONTHU SAHITA',
      rollnumber: '18VV1A1209',
      branch: 'IT',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'TCS ,Wipro'
  },
  {
      sno: 148,
      name: 'MANIKANTA BOYINA',
      rollnumber: '18VV1A1210',
      branch: 'IT',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Cognizant Pvt Ltd'
  },
  {
      sno: 149,
      name: 'DEVARABHOTLA GAYATHRI',
      rollnumber: '18VV1A1212',
      branch: 'IT',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Accenture'
  },
  {
      sno: 150,
      name: 'KOLLURI GAYATRI',
      rollnumber: '18VV1A1213',
      branch: 'IT',
      passingyear: '2021-2022',
      campus: 'On Campus (Virtual)',
      company: 'Accenture'
  },
  {
    sno: 151,
    name: 'GORLA SAI SANDEEP',
    rollnumber: '18VV1A1214',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 152,
    name: 'GUBBALA BHASKARA PRASADU',
    rollnumber: '18VV1A1215',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'L&T Constructions'
},
{
    sno: 153,
    name: 'GULLIPALLI GAYATRI',
    rollnumber: '18VV1A1216',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Accenture'
},
{
    sno: 154,
    name: 'GURRAM NIHARIKA',
    rollnumber: '18VV1A1217',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Accenture'
},
{
    sno: 155,
    name: 'JARAPALA GANESH',
    rollnumber: '18VV1A1219',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 156,
    name: 'DEEPIKA IMANDI',
    rollnumber: '18VV1A1218',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Accenture'
},
{
    sno: 157,
    name: 'KANDA PRADEEP',
    rollnumber: '18VV1A1220',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Infosys'
},
{
    sno: 158,
    name: 'CHANDRIKA VENKAT KORLAM',
    rollnumber: '18VV1A1221',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 159,
    name: 'KUNJAM.RAVI CHANDANA',
    rollnumber: '18VV1A1222',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 160,
    name: 'KURMAPU HYMAVATHI',
    rollnumber: '18VV1A1223',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 161,
    name: 'LAKSHMI VARAPRASAD GATIGANTI',
    rollnumber: '18VV1A1224',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 162,
    name: 'MALIPEDDI HANISHA',
    rollnumber: '18VV1A1225',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Accenture'
},
{
    sno: 163,
    name: 'MOGALI RAMYASRI',
    rollnumber: '18VV1A1226',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 164,
    name: 'MOTUPALLI VIJAYA SAI PRIYANKA',
    rollnumber: '18VV1A1228',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Leoforce'
},
{
    sno: 165,
    name: 'HARSHINI PRAVALLIKA MUPPIDI',
    rollnumber: '18VV1A1229',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Accenture'
},
{
    sno: 166,
    name: 'NUNNA SWAHAN BAPAJI',
    rollnumber: '18VV1A1230',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'L&T Constructions'
},
{
    sno: 167,
    name: 'PADALA PADMA RAMA RAJU',
    rollnumber: '18VV1A1231',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Leoforce'
},
{
    sno: 168,
    name: 'PALAPATI DATTA SAI VENKATA SRAVAN',
    rollnumber: '18VV1A1232',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 169,
    name: 'PAMPANA MAHA SRAVANI',
    rollnumber: '18VV1A1233',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'L&T Constructions'
},
{
    sno: 170,
    name: 'CHAARU SAILAJA PARANGI',
    rollnumber: '18VV1A1234',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Accenture'
},
{
    sno: 171,
    name: 'DIVYA PRAKESH',
    rollnumber: '18VV1A1235',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Accenture'
},
{
    sno: 172,
    name: 'PREETHI KORUKURI',
    rollnumber: '18VV1A1236',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 173,
    name: 'KRISHNA BHAGAT RACHAMANTI',
    rollnumber: '18VV1A1237',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 174,
    name: 'S BISWAJEET MADHAV',
    rollnumber: '18VV1A1239',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 175,
    name: 'SANAPALA SAI KRISHNA',
    rollnumber: '18VV1A1240',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 176,
    name: 'SHAIK AZEEZ HASSAN',
    rollnumber: '18VV1A1242',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'TCS'
},
{
    sno: 177,
    name: 'SHAIK GOUSE JANI',
    rollnumber: '18VV1A1243',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'TCS'
},
{
    sno: 178,
    name: 'SAI PRANEETH TARLANA',
    rollnumber: '18VV1A1244',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 179,
    name: 'USHARANI UPPADA',
    rollnumber: '18VV1A1245',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Infosys'
},
{
    sno: 180,
    name: 'UPPUGUNDURU SRIRAM ANUSH',
    rollnumber: '18VV1A1246',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Sails.Software Solutions Pvt.ltd'
},
{
    sno: 181,
    name: 'V THIRUMALA NIRANJAN REDDY',
    rollnumber: '18VV1A1247',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 182,
    name: 'YALAKALA KAVYA',
    rollnumber: '18VV1A1248',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'TCS'
},
{
    sno: 183,
    name: 'MANEESHA YANDA',
    rollnumber: '18VV1A1249',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 184,
    name: 'YESHWANTH BELAMANA',
    rollnumber: '18VV1A1251',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 185,
    name: 'APPINEDI NAVEEN',
    rollnumber: '19VV5A1261',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 186,
    name: 'BALA KODANDA RAMAIAH ARJA',
    rollnumber: '18VV5A1262',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 187,
    name: 'CHAGAM VINOD',
    rollnumber: '19VV5A1264',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 188,
    name: 'UMA NAGARAJU DEVANABOINA',
    rollnumber: '19VV5A1265',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 189,
    name: 'KALLA KRUPAVARAM',
    rollnumber: '19VV5A1266',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 190,
    name: 'KURAMANA MAHESH BABU',
    rollnumber: '19VV5A1269',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Cognizant Pvt Ltd'
},
{
    sno: 191,
    name: 'PARSAPOGU VINAY',
    rollnumber: '19VV5A1270',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 192,
    name: 'RATNALA SAI JAHNAVI',
    rollnumber: '19VV5A1271',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 193,
    name: 'SAMBHANA PRIYA LAKSHMI',
    rollnumber: '19VV5A1272',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 194,
    name: 'SATHIVILLI SANDYA RANI',
    rollnumber: '19VV5A1273',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Innominds'
},
{
    sno: 195,
    name: 'WUNNA SAILAJA',
    rollnumber: '19VV5A1274',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'CGI'
},
{
    sno: 196,
    name: 'SANDHYA SIRAPURAPU',
    rollnumber: '19VV5A1275',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Pentagon Space CSR'
},
{
    sno: 197,
    name: 'BOPPE SREE HARSHA',
    rollnumber: '16VV1A1203',
    branch: 'IT',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Wipro'
},
{
    sno: 198,
    name: 'AKULA BHARAT KUMAR',
    rollnumber: '18VV1A3101',
    branch: 'MET',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'JSW Jindal Steel Ltd.'
},
{
    sno: 199,
    name: 'AVVARU NAVEEN CHAKRAVARTHY',
    rollnumber: '18VV1A3102',
    branch: 'MET',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'JSW Jindal Steel Ltd.'
},
{
    sno: 200,
    name: 'BODDEPALLI SAHITHI',
    rollnumber: '18VV1A3104',
    branch: 'MET',
    passingyear: '2021-2022',
    campus: 'On Campus (Virtual)',
    company: 'Byjus'
},
{
  sno: 201,
  name: 'BOLLA MALLIKA',
  rollnumber: '18VV1A3105',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'JSW Jindal Steel Ltd.'
},
{
  sno: 202,
  name: 'GANTA DURGA SANKAR SABARISH',
  rollnumber: '18VV1A3106',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'JSW Jindal Steel Ltd.'
},
{
  sno: 203,
  name: 'GOGU TARUNYA SAROJINI',
  rollnumber: '18VV1A3107',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Infosys'
},
{
  sno: 204,
  name: 'KATTA MANASA',
  rollnumber: '18VV1A3109',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Byjus'
},
{
  sno: 205,
  name: 'MITTIREDDI SRAVAN KUMAR',
  rollnumber: '18VV1A3111',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'JSW Jindal Steel Ltd.'
},
{
  sno: 206,
  name: 'N.JAHNAVI',
  rollnumber: '18VV1A3112',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Wipro'
},
{
  sno: 207,
  name: 'PAKALAPATI SRI SAI BHAVANA',
  rollnumber: '18VV1A3113',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 208,
  name: 'PONDURU RAJESH',
  rollnumber: '18VV1A3115',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Accenture'
},
{
  sno: 209,
  name: 'S.MADHURI',
  rollnumber: '18VV1A3116',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Wipro'
},
{
  sno: 210,
  name: 'SRIRAM SRIVALLI',
  rollnumber: '18VV1A3118',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'JSW Jindal Steel Ltd.'
},
{
  sno: 211,
  name: 'JAYA SRI V',
  rollnumber: '18VV1A3119',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'JSW Jindal Steel Ltd.'
},
{
  sno: 212,
  name: 'YALLA UMA MAHESHWARI',
  rollnumber: '18VV1A3122',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'JSW Jindal Steel Ltd.'
},
{
  sno: 213,
  name: 'NARAM NAIDU MEESALA',
  rollnumber: '19VV5A3135',
  branch: 'MET',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Infosys'
},
{
  sno: 214,
  name: 'KAPU SRAVANI',
  rollnumber: '18VV1A0108',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 215,
  name: 'KELLA BHARATHI',
  rollnumber: '18VV1A0109',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 216,
  name: 'PATNALA SURENDRA',
  rollnumber: '18VV1A0116',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 217,
  name: 'PUDI SAI GOWTHAM AKHIL',
  rollnumber: '18VV1A0117',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Infosys'
},
{
  sno: 218,
  name: 'VEERABOINA HARSHA',
  rollnumber: '18VV1A0123',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 219,
  name: 'VELLA SAI SANDEEP',
  rollnumber: '18VV1A0124',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 220,
  name: 'TURPATI HEMANTH KUMAR',
  rollnumber: '19VV5A0137',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 221,
  name: 'Y HARI BHEEMA ADHI NARAYANASWAMY',
  rollnumber: '19VV5A0139',
  branch: 'CIVIL',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 222,
  name: 'LAVANYA',
  rollnumber: '20VV1F0006',
  branch: 'MCA',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Capgemini'
},
{
  sno: 223,
  name: 'S NAVEEN KUMAR',
  rollnumber: '20VV1F0024',
  branch: 'MCA',
  passingyear: '2021-2022',
  campus: 'On Campus',
  company: 'KelltonTech Pvt Ltd'
},
{
  sno: 224,
  name: 'KATTA RAJESH',
  rollnumber: '19VV1F0013',
  branch: 'MCA',
  passingyear: '2021-2022',
  campus: 'On Campus',
  company: 'KelltonTech Pvt Ltd'
},
{
  sno: 225,
  name: 'KATTA RAJESH',
  rollnumber: '19VV1F0013',
  branch: 'MCA',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Pentagon Space CSR'
},
{
  sno: 226,
  name: 'NANDYALA PALLAVI',
  rollnumber: '19VV1F0019',
  branch: 'MCA',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Infosys'
},
{
  sno: 227,
  name: 'HARSHINI KASI',
  rollnumber: '',
  branch: 'ECE',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 228,
  name: 'KOTA PAVAN KUMAR',
  rollnumber: '20VV1D2108',
  branch: 'M.Tech- Thermal Engineering',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Pentagon Space CSR'
},
{
  sno: 229,
  name: 'MEKALA KUMAR',
  rollnumber: '20VV1D2110',
  branch: 'M.Tech (Thermal Engineering)',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'L&T Constructions'
},
{
  sno: 230,
  name: 'PAVAN KALYAN GOLLAPALLI',
  rollnumber: '20VV1D2113',
  branch: 'M.Tech- Thermal Engineering',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'TCS'
},
{
  sno: 231,
  name: 'LANKE RAMA KRISHNA VARMA',
  rollnumber: '20VV1D2408',
  branch: 'M.Tech (MST)',
  passingyear: '2021-2022',
  campus: 'On Campus (Virtual)',
  company: 'Infosys'
},
{
  sno: 232,
  name: 'ADITYA KAHSYAP',
  rollnumber: '20VV1D4502',
  branch: 'M.Tech (ECE)',
  passingyear: '2021-2022',
  campus: 'On Campus',
  company: 'CGI'
},
];
  
  export default studentsPlacedData;
  