const studentsPlacedData = [
    {
      sno: 1,
      name: "ACHANTA JAHNAVI",
      rollnumber: "16VV1A0501",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Virtusa",
    },
    {
      sno: 2,
      name: "BALARAM REVANTH NAMBURU",
      rollnumber: "16VV1A0503",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "NCR Corporation India Pvt.Ltd.",
    },
    {
      sno: 3,
      name: "BIJJAM SATISH KUMAR REDDY",
      rollnumber: "16VV1A0505",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 4,
      name: "BORRA SURYA NAGA PRIYANKA",
      rollnumber: "16VV1A0507",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "NCR Corporation India Pvt.Ltd.",
    },
    {
      sno: 5,
      name: "CHAMARTHY NAGA SAI SRIRAM",
      rollnumber: "16VV1A0511",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 6,
      name: "DODLA INDU PRIYANKA",
      rollnumber: "16VV1A0513",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 7,
      name: "BHANU PRAKASH",
      rollnumber: "16VV1A0515",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 8,
      name: "GEDELA NAVYA SREE",
      rollnumber: "16VV1A0519",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 9,
      name: "HIMANSI PANDA",
      rollnumber: "16VV1A0520",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 10,
      name: "KAMALAPATI TULASI",
      rollnumber: "16VV1A0523",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Wipro",
    },
    {
      sno: 11,
      name: "KARRA GANAPATHI",
      rollnumber: "16VV1A0525",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "NCR Corporation India Pvt.Ltd.",
    },
    {
      sno: 12,
      name: "K. SUSANNA",
      rollnumber: "16VV1A0526",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 13,
      name: "KONDAPALLI BHAVANA",
      rollnumber: "16VV1A0527",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 14,
      name: "GUMMALLA DEVIKA",
      rollnumber: "16VV1A0528",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 15,
      name: "LAKSHMANAN AMRUTHA",
      rollnumber: "16VV1A0530",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Wipro",
    },
    {
      sno: 16,
      name: "JENNY PRASANTHI",
      rollnumber: "16VV1A0531",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 17,
      name: "MADDELA HEMALATHA",
      rollnumber: "16VV1A0533",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 18,
      name: "MANDAPALLI DURGA MALLESH",
      rollnumber: "16VV1A0535",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 19,
      name: "NARINA HIMAJA",
      rollnumber: "16VV1A0537",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Apps Associates",
    },
    {
      sno: 20,
      name: "NIRBHAY KUMAR SINGH",
      rollnumber: "16VV1A0538",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "NCR Corporation India Pvt.Ltd.",
    },
    {
      sno: 21,
      name: "N. VENKATA ABHILASH",
      rollnumber: "16VV1A0539",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 22,
      name: "POLINA POOJA CHOWDHARY",
      rollnumber: "16VV1A0540",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 23,
      name: "P. SURYA GANESH",
      rollnumber: "16VV1A0542",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 24,
      name: "PULI AJAY",
      rollnumber: "16VV1A0543",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Tilicho Labs",
    },
    {
      sno: 25,
      name: "PAPPALA RAMESH",
      rollnumber: "16VV1A0545",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 26,
      name: "RAYAVARAPU GOWTHAM",
      rollnumber: "16VV1A0546",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 27,
      name: "REDDI DEVI",
      rollnumber: "16VV1A0547",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 28,
      name: "SHAIK RIYAZ",
      rollnumber: "16VV1A0549",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions",
    },
    {
      sno: 29,
      name: "SHAIK SEEMA",
      rollnumber: "16VV1A0550",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 30,
      name: "SHAIK SHAJEED SULTANA",
      rollnumber: "16VV1A0551",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Wipro",
    },
    {
      sno: 31,
      name: "SIRIPURAPU RESHMA BHANU",
      rollnumber: "16VV1A0552",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Efftronics",
    },
    {
      sno: 32,
      name: "SUNKARA RANJITHA",
      rollnumber: "16VV1A0553",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 33,
      name: "THOTA PURNIMA",
      rollnumber: "16VV1A0555",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 34,
      name: "APPALA NAIDU",
      rollnumber: "16VV1A0557",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Tilicho Labs",
    },
    {
      sno: 35,
      name: "VEMPARALA BRAHMANI SRI MAHA LAKSHMI",
      rollnumber: "16VV1A0558",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 36,
      name: "YELURI NEEHARIKA",
      rollnumber: "16VV1A0559",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Wipro",
    },
    {
      sno: 37,
      name: "PADALA SITA",
      rollnumber: "17VV5A0563",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions",
    },
    {
      sno: 38,
      name: "NAGA SAI",
      rollnumber: "17VV5A0565",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Tilicho Labs",
    },
    {
      sno: 39,
      name: "GONGADA SAHITHYA",
      rollnumber: "17VV5A0566",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions",
    },
    {
      sno: 40,
      name: "P.VANITHA",
      rollnumber: "15VV5A0568",
      branch: "CSE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 41,
      name: "CHINTAPALLI SYAM CHARAM",
      rollnumber: "16VV1A1204",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 42,
      name: "GOGADA CHANDRA VAMSI",
      rollnumber: "16VV1A1214",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 43,
      name: "YARAMALA VIVEK",
      rollnumber: "16VV1A1258",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 44,
      name: "GANDI DIVYA",
      rollnumber: "16VV1A1210",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 45,
      name: "SALINA SARITHA",
      rollnumber: "16VV1A1243",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 46,
      name: "PANNEERU VAMSEE",
      rollnumber: "16VV1A1237",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 47,
      name: "KUPIREDDY SANDHYARANI",
      rollnumber: "16VV1A1228",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 48,
      name: "ERRAMSETTY JYOTHSNA",
      rollnumber: "16VV1A1209",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 49,
      name: "PANDIRI SURESH",
      rollnumber: "16VV1A1236",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 50,
      name: "CHOPPAVARAPU PURNIMA",
      rollnumber: "16VV1A1205",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 51,
      name: "KOTHAPALLI PRIYANKA",
      rollnumber: "16VV1A1226",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 52,
      name: "ANDAVARAPU VINEELA",
      rollnumber: "16VV1A1201",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 53,
      name: "TADALA VENKATA SAI SUSHMA",
      rollnumber: "16VV1A1257",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 54,
      name: "VAMSI SHANKAR SIMHADRI",
      rollnumber: "16VV1A1247",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 55,
      name: "NADEEM BABU SHAIK",
      rollnumber: "16VV1A1246",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 56,
      name: "SEERA SAI LIKHITH YADAV",
      rollnumber: "16VV1A1244",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services",
    },
    {
      sno: 57,
      name: "CHOPPAVARAPU PURNIMA",
      rollnumber: "16VV1A1205",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 58,
      name: "GEDELA DIVYA SRI",
      rollnumber: "16VV1A1211",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 59,
      name: "GONA BUELAH BLESSINA",
      rollnumber: "16VV1A1216",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 60,
      name: "KOTHAPALLI PRIYANKA",
      rollnumber: "16VV1A1226",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 61,
      name: "PANNEERU VAMSEE",
      rollnumber: "16VV1A1237",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 62,
      name: "SEERA SAI LIKHITH YADAV",
      rollnumber: "16VV1A1244",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 63,
      name: "ADADADI TIRUMALESWER",
      rollnumber: "16VV1A1261",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions",
    },
    {
      sno: 64,
      name: "CHOPPAVARAPU PURNIMA",
      rollnumber: "16VV1A1205",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 65,
      name: "ERRAMSETTY JYOTHSNA",
      rollnumber: "16VV1A1209",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 66,
      name: "GANDI DIVYA",
      rollnumber: "16VV1A1210",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 67,
      name: "GONA BUELAH BLESSINA",
      rollnumber: "16VV1A1216",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 68,
      name: "HANUMANTU HIMA BINDU",
      rollnumber: "16VV1A1219",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 69,
      name: "MOTHUKURI AKHILA",
      rollnumber: "16VV1A1233",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 70,
      name: "NAGAPURI TEJA",
      rollnumber: "16VV1A1234",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys",
    },
    {
      sno: 71,
      name: "REDDY SAI GEETHIKA",
      rollnumber: "16VV1A1241",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys"
    },
    {
      sno: 72,
      name: "SEERA SAI LIKHITH YADAV",
      rollnumber: "16VV1A1244",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys"
    },
    {
      sno: 73,
      name: "GEDELA SAI GOWTHAMI",
      rollnumber: "16VV1A1212",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Wipro"
    },
    {
      sno: 74,
      name: "SEERA SAI LIKHITH YADAV",
      rollnumber: "16VV1A1244",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Wipro"
    },
    {
      sno: 75,
      name: "GONA BUELAH BLESSINA",
      rollnumber: "16VV1A1216",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "IBM India Pvt.Ltd"
    },
    {
      sno: 76,
      name: "JAVVADI SAI JYOTHSNA",
      rollnumber: "16VV1A1221",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Efftronics"
    },
    {
      sno: 77,
      name: "DUGRA RAO PINNINTI",
      rollnumber: "16VV1A1208",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 78,
      name: "GULLAPALLI KAMESH",
      rollnumber: "16VV1A1217",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 79,
      name: "KONDITI CHNADRA SEKHAR",
      rollnumber: "16VV1A1224",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 80,
      name: "DERANGULA PAVAN KUMAR",
      rollnumber: "16VV1A1206",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 81,
      name: "DURGARAO PINNINTI",
      rollnumber: "16VV1A1208",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 82,
      name: "GULLAPALLI KAMESH",
      rollnumber: "16VV1A1217",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 83,
      name: "KONDETI CHANDRA SEKHAR",
      rollnumber: "16VV1A1224",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 84,
      name: "KOTAMSETTY MAHESH",
      rollnumber: "16VV1A1225",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 85,
      name: "SIMHADRI VAMSI SHANKAR",
      rollnumber: "16VV1A1247",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Sails Software Solutions"
    },
    {
      sno: 86,
      name: "JYOTHSNA ERRAMSETTY",
      rollnumber: "16VV1A1209",
      branch: "IT",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions"
    },
    {
      sno: 87,
      name: "A.V.S.VISHNU PRIYA",
      rollnumber: "16VV1A0401",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services"
    },
    {
      sno: 88,
      name: "BANDARU PAVAN KALYAN",
      rollnumber: "16VV1A0404",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services"
    },
    {
      sno: 89,
      name: "BOLEM RUPA SRI SWATHI",
      rollnumber: "16VV1A0405",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Wipro"
    },
    {
      sno: 90,
      name: "SEEDARI CHANDRA MOHAN",
      rollnumber: "16VV1A0406",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Banking"
    },
    {
      sno: 91,
      name: "DHAVALA SUDHA SARIKA",
      rollnumber: "16VV1A0408",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "NCR Corporation India Pvt.Ltd."
    },
    {
      sno: 92,
      name: "G.LEELA SARASWATHI",
      rollnumber: "16VV1A0415",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Panchayat Secretary(Grade-IV)"
    },
    {
      sno: 93,
      name: "JANAKIRAM SUHAS GOLI",
      rollnumber: "16VV1A0416",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions"
    },
    {
      sno: 94,
      name: "SAI KISHORE KODIMELA",
      rollnumber: "16VV1A0421",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cerium Systems"
    },
    {
      sno: 95,
      name: "MUNDURU AVINASH",
      rollnumber: "16VV1A0435",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Wipro"
    },
    {
      sno: 96,
      name: "PADMANABHUNI BHAVYASRI NAGA TULASI MONISHA",
      rollnumber: "16VV1A0439",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cerium Systems"
    },
    {
      sno: 97,
      name: "PALUTLA BALA SAI MANASA",
      rollnumber: "16VV1A0440",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys"
    },
    {
      sno: 98,
      name: "SHAIK RIZWAN",
      rollnumber: "16VV1A0447",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "AFCAT"
    },
    {
      sno: 99,
      name: "TANGELLA CHAKRAVARTHI",
      rollnumber: "16VV1A0452",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Wipro"
    },
    {
      sno: 100,
      name: "VENKUMAHANTI RENUSRI",
      rollnumber: "16VV1A0454",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Wipro"
    },
    {
      sno: 101,
      name: "Y RAMA KRISHNA",
      rollnumber: "16VV1A0459",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services"
    },
    {
      sno: 102,
      name: "VARADA SATYA LALITHA SAGARI",
      rollnumber: "16VV1A0461",
      branch: "ECE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Infosys"
    },
    {
      sno: 103,
      name: "MUNAGAVALASA SAIRAMYA",
      rollnumber: "16VV1A0232",
      branch: "EEE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services"
    },
    {
      sno: 104,
      name: "NAGURU SAI SHARMILA",
      rollnumber: "16VV1A0233",
      branch: "EEE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services"
    },
    {
      sno: 105,
      name: "SAHUKARU VINELA PADMASAI",
      rollnumber: "16VV1A0242",
      branch: "EEE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions"
    },
    {
      sno: 106,
      name: "SHAIK JAHANGEER BASHA",
      rollnumber: "16VV1A0246",
      branch: "EEE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Wipro"
    },
    {
      sno: 107,
      name: "SRAVANI MUDARABILLI",
      rollnumber: "16VV1A0249",
      branch: "EEE",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "HCL"
    },
    {
      sno: 108,
      name: "VINAY VENKATESH DARLA",
      rollnumber: "16VV1A0252",
      branch: "EEE",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Cognizant Technology Solutions"
    },
    {
      sno: 109,
      name: "K. GANESH",
      rollnumber: "16VV1A0131",
      branch: "CIVIL",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "AP Govt Eng Asst"
    },
    {
      sno: 110,
      name: "K.GOUTHAM",
      rollnumber: "16VV1A0110",
      branch: "CIVIL",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services"
    },
    {
      sno: 111,
      name: "N.HARIVARMA",
      rollnumber: "16VV1A0316",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Cognizant Technology Solutions"
    },
    {
      sno: 112,
      name: "K.PRASANTH",
      rollnumber: "16VV1A0326",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Tata Consultancy Services"
    },
    {
      sno: 113,
      name: "SSS N M M SATWICK",
      rollnumber: "16VV1A0329",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Infosys"
    },
    {
      sno: 114,
      name: "MD.PARVEZ",
      rollnumber: "16VV1A0331",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "On Campus",
      company: "Everglades"
    },
    {
      sno: 115,
      name: "SHAIKSHELABANU",
      rollnumber: "16VV1A0345",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Cognizant Technology Solutions"
    },
    {
      sno: 116,
      name: "K.VENKATAGIREESH",
      rollnumber: "17VV5A0363",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "Cognizant Technology Solutions"
    },
    {
      sno: 117,
      name: "K.RAMAKRISHNA",
      rollnumber: "17VV5A0365",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "NSTL – Senior Technical Assistant"
    },
    {
      sno: 118,
      name: "M.SAI KUMAR",
      rollnumber: "17VV5A0366",
      branch: "MECH",
      passingyear: "2019-2020",
      campus: "Off Campus",
      company: "NSTL – Senior Technical Assistant"
    }
  ];
  
  export default studentsPlacedData;
  